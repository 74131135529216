import {Component, AfterViewInit, ViewChild, OnInit} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import {ChatApiService, IDialog} from '@services/api/chat-api.service';
import {SupportService} from '@components/support/support.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.pug',
  styleUrls: ['./support.component.sass']
})
export class SupportComponent implements OnInit, AfterViewInit {

  @ViewChild('supportTrigger') supportTrigger: MatMenuTrigger;
  @ViewChild('chatTrigger') chatTrigger: MatMenuTrigger;
  @ViewChild('chat') chat: MatMenu;

  dialogs: IDialog[] = [];
  unread: number;
  activeChat: IDialog | null = null;
  subscriptions: Subscription[] = [];

  constructor(private apiService: ChatApiService,
              private supportService: SupportService) {
  }

  ngOnInit(): void {
    this.initDialogs();
  }

  initDialogs() {

    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];

    this.apiService.getDialogs()
      .subscribe(data => {
        this.dialogs = data.data;
        this.unread = this.dialogs
          .map(x => x.Unread)
          .reduce((sum, current) => sum + current, 0);

        this.dialogs.forEach(d => {

          const subscription = this.apiService
            .subscribeToMessage(d.Id)
            .subscribe(() => {
              if (this.chatTrigger.menuOpen) {
                this.supportService.newMessage$.next(true);
              }
              this.initDialogs();
            });

          this.subscriptions.push(subscription);
        });
      });
  }

  closeDialogs() {
    this.supportTrigger.closeMenu();
    this.closeChat();
  }

  openChat(id?: number) {
    this.activeChat = id
      ? this.dialogs.find(x => x.Id === id)
      : {} as IDialog;
    this.chatTrigger.openMenu();
  }

  closeChat() {
    this.chatTrigger.closeMenu();
    this.activeChat = null;
  }

  ngAfterViewInit(): void {
    this.chat.setElevation(4);
  }
}
