import { NgModule } from '@angular/core';
import { StoreTypes } from '../store-types';
import { invitedUsersReducer } from '@app/_stores/invited-users/invited-users.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InvitedUsersEffects } from '@app/_stores/invited-users/invited-users.effects';

@NgModule({
  imports:[
    StoreModule.forFeature(StoreTypes.invitedUsers, invitedUsersReducer),
    EffectsModule.forFeature([InvitedUsersEffects])
  ]
})
export class InvitedUsersStoreModule{}
