import {Component} from '@angular/core';
import {ConstConfig} from '../../../../../_config/const.config';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.pug',
  styleUrls: ['./registration-success.component.sass']
})
export class RegistrationSuccessComponent {

  constConfig = ConstConfig;

  constructor() {
  }

}
