import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '../env/env.service';
import { ApiClientBase } from './api-client-base.service';
import { AppHeadersService } from '../headers/app-headers.service';


@Injectable()
export class AdminApiClient extends ApiClientBase {
  constructor(http: HttpClient, headers: AppHeadersService, hosts: EnvService) {
    super(http, headers, () => hosts.adminUrl);
  }
}
