import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportComponent } from './support.component';
import { DialogsComponent } from './dialogs/dialogs.component';
import { ChatComponent } from './chat/chat.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { SupportAttachmentComponent } from './support-attachment/support-attachment.component';
import { SupportAttachmentViewComponent } from './support-attachment-view/support-attachment-view.component';
import { SupportMsgViewComponent } from './support-msg-view/support-msg-view.component';
import { MatBadgeModule } from "@angular/material/badge";
import { FlexModule } from "@angular/flex-layout";
import { FileDropModule } from "@components/support/file-drop/file-drop.module";
import { MaterialModule } from "@material-module";

@NgModule({
  declarations: [
    SupportComponent,
    DialogsComponent,
    ChatComponent,
    SupportAttachmentComponent,
    SupportAttachmentViewComponent,
    SupportMsgViewComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatBadgeModule,
    FlexModule,
    FileDropModule,
    MaterialModule
  ],
  exports: [
    SupportComponent
  ]
})

export class SupportModule {

}
