import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from '@services/authentication.service';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { ProfileService } from '@services/profile.service';
import { environment } from '@environments/environment';
import { IdentityClient, OrganizationRequestType, OrganizationResult, SetOrganizationAgentRequestTypeViewModel } from '@api/IdentityClient';
import { filter, first, flatMap } from 'rxjs/operators';
import * as MobileDetect from 'mobile-detect';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.pug',
  styleUrls: ['./header.component.sass']
})

export class HeaderComponent implements OnInit {

  organizationRequest$: Observable<OrganizationResult[]>;

  fullName = new BehaviorSubject<string>('');
  email = new BehaviorSubject<string>('');
  refreshOrganizationRequest = new Subject();

  socialNetworkUrl = environment.api.socialNetwork;
  requestTypeEnum = OrganizationRequestType;

  mobileDetect = new MobileDetect(window.navigator.userAgent);

  mobileMenuOpen = false;
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public authenticationService: AuthenticationService,
              public profileService: ProfileService,
              public identityClient: IdentityClient,
              private location: Location,
              private router: Router) {
    this.authenticationService.authenticatedUserData$.subscribe(user => {
        if (user) {
          const fullName = user.firstName || user.lastName
            ? `${ user.firstName ? user.firstName : '' } ${ user.lastName ? user.lastName : '' }`
            : user.email;
          this.fullName.next(fullName);

          const email = user.email;
          this.email.next(email);
        }
      }
    );
  }

  ngOnInit() {
    this.organizationRequest$ = merge(this.profileService.currentProfile$, this.refreshOrganizationRequest)
      .pipe(flatMap(() => this.profileService.currentProfile$.pipe(first())))
      .pipe(filter(profile => !!profile))
      .pipe(flatMap(data => this.identityClient.getOrganizationRequests(data.id)));
  }

  changeRequestType(id: number, requestType: OrganizationRequestType): void {
    this.profileService.currentProfile$
      .pipe(first())
      .pipe(flatMap(data => {
        const model = new SetOrganizationAgentRequestTypeViewModel({
          agentProfileId: data.id,
          organizationProfileId: id,
          requestType: requestType
        });
        return this.identityClient.setOrganizationAgentRequestType(model);
      }))
      .subscribe(() => {
        this.refreshOrganizationRequest.next(null);
      });
  }

  goBack() {
    this.location.back();
  }

  isActive(path: string): boolean {
    const reg = new RegExp('^\\/profile\\/[1-9]\\d{0,5}$');
    if (reg.test(this.router.url) && path === '/contractors') {
      return true;
    }
    if (path === '/') {
      return this.router.url === path || this.router.url.includes('wallet');
    }
    return this.router.url.includes(path);
  }
}
