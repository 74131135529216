import {Component, ElementRef, ViewChild} from '@angular/core';
import {SocialAuthBase} from '../SocialAuthBase';
import {AuthenticationService} from '@services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OAuthSystem} from '@api/IdentityClient';
import {MatDialog} from '@angular/material/dialog';
import {SocialAuthService} from '@abacritt/angularx-social-login';
import {CustomGoogleProvider} from '@app/modules/lazy/+auth/components/login-registration/components/google-auth/custom-google-provider';

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.pug',
  styleUrls: ['./google-auth.component.sass']
})
export class GoogleAuthComponent extends SocialAuthBase {

  @ViewChild('hiddenSignInWithGoogle') hiddenSignInWithGoogle: ElementRef;

  constructor(socialAuthService: SocialAuthService,
              authenticationService: AuthenticationService,
              router: Router,
              route: ActivatedRoute,
              dialog: MatDialog) {
    super(socialAuthService, authenticationService, router, route, dialog);
  }

  protected providerId = CustomGoogleProvider.PROVIDER_ID;
  protected system = OAuthSystem.Google;

  protected subscribeToAuhState() {
  }
}
