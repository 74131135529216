import { ActionReducer } from '@ngrx/store';

export function tryCatchReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        try {
            return reducer(state, action);
        } catch (ex) {
            console.error('Reducer exception:', ex);
            return state;
        }
    };
}
