import { State } from './invited-users.state';
import { Actions, ActionTypes } from '@app/_stores/invited-users/invited-users.actions';

const initialState: State = {
  invitedWallets: null,
  isLoading: false,
  errors: null
};

export function invitedUsersReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.FETCH_INVITED_WALLETS:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.SET_INVITED_WALLETS:
      return {
        ...state,
        isLoading: false,
        invitedWallets: action.payload
      };
    case ActionTypes.SET_ERRORS:
      return {
        ...state,
        isLoading: false,
        errors: action.payload
      };
    default:
      return state;
  }
}

