import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IdentityClient} from '@api/IdentityClient';

@Component({
  selector: 'app-two-fa-reminder',
  templateUrl: './two-fa-reminder.component.pug',
  styleUrls: ['./two-fa-reminder.component.sass']
})
export class TwoFaReminderComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<TwoFaReminderComponent>) { }

  ngOnInit() {
  }
}
