import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidatorConfig } from '../../../../../../_config/validator.config';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateAccountRequest, AccountType, ErrorResponse, IdentityClient } from '@api/IdentityClient';
import { AuthConfig } from '../../../../../../_config/auth.config';
import { ConstConfig } from '../../../../../../_config/const.config';
import { InviteCodeCookiesService } from '../../../../../../_services/cookies/invite-code-cookies.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.pug',
  styleUrls: ['./registration.component.sass']
})
export class RegistrationComponent implements OnInit {
  @Input() inviteCode: string;
  @Output() onLoading = new EventEmitter<boolean>();
  @Output() toggle: EventEmitter<null> = new EventEmitter();

  validatorConfig = ValidatorConfig;
  createAccountRequestAccountType = AccountType;
  submitted = false;
  showPwd = false;
  showPwdRepeat = false;
  error: string;
  selectedAccountType = AccountType.Personal;
  tooltip = `You must read and agree to the User's Agreement and Privacy Policy`;
  returnUrl: string;

  singUpForm = this.formBuilder.group({
    email: ['', [ValidatorConfig.EmailValidator]],
    inviteCode: [''],
    password: ['', [ValidatorConfig.PasswordValidator]],
    confirmPassword: ['', [Validators.required, RxwebValidators.compare({fieldName: 'password'})]],
    termsAcc: ['']
  });

  constructor(protected router: Router,
              private identityClient: IdentityClient,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private inviteCodeCookiesService: InviteCodeCookiesService) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || ConstConfig.DefaultRoute;
    if (this.inviteCode)
      this.inviteCodeCookiesService.Set(this.inviteCode);
  }

  toLogin() {
    this.toggle.emit();
  }

  onSubmit() {
    this.submitted = true;

    if (this.singUpForm.invalid) {
      return;
    }

    this.onLoading.next(true);
    this.error = '';

    const request = new CreateAccountRequest(this.singUpForm.value);
    request.confirmationAction = AuthConfig.LoginConfirmationAction;
    request.accountType = this.selectedAccountType;

    this.identityClient.createUser(request)
      .subscribe(async () => {
          localStorage.setItem('email', this.singUpForm.value.email);
          this.router.navigate(['/auth', 'registration-complete']);
          this.onLoading.next(false);
        },
        (error: ErrorResponse) => {
          this.error = error.message;
          this.onLoading.next(false);
        });
  }
}
