import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { ApiClient } from '@services/api/api-client.service';
import { InvitedWalletsChainedResult } from '@app/_models/invited-users/invited-wallets-chained.result';
import { catchError, map } from 'rxjs/operators';
import { AdminApiClient } from '@services/api/admin-api-client.service';
import { UsersStoreActions } from './index';
import { FetchUserProperties, FetchUsersByIds, SetCountInvitedUsers, SetErrors, SetUserProperties } from './users.actions';
import { UserPropertyView } from '@app/_models/users/user-property-view';


@Injectable()
export class UsersEffects {
  rootRoute = '/api/users';
  requestRoute = (request: string = null) => `${ this.rootRoute }${ request ? '/' + request : '' }`;

  constructor(private store$: Store<AppState>, private actions$: Actions, private apiClient: ApiClient, private adminClient: AdminApiClient) {
  }

  fetchUsersByIds$ = createEffect(() => this.actions$.pipe(
    ofType<UsersStoreActions.FetchUsersByIds>(UsersStoreActions.ActionTypes.FETCH_USERS_BY_IDS),
    switchMap((action) => {
        if (action.ids?.length > 0)
          return this.adminClient.post<any[]>(this.requestRoute('array/short-info'), {ids: action.ids})
            .pipe(
              map((a) => {
                return new UsersStoreActions.SetUsers(a);
              }),
              catchError(err => {
                return of(new UsersStoreActions.SetErrors(err));
              })
            );
        else
          return of(new UsersStoreActions.SetUsers(null));
      }
    )
  ));

  fetchUsersByInvitedUsersResult$ = createEffect(() => this.actions$.pipe(
    ofType<UsersStoreActions.FetchChainInvitedWallets>(UsersStoreActions.ActionTypes.FETCH_CHAIN_INVITED_WALLETS),
    switchMap((action: UsersStoreActions.FetchChainInvitedWallets) => {
        let ids = this.getIdsByInvitedUsersChainedResult(action.payload);
        return of(new FetchUsersByIds(ids));
      }
    )
  ));

  fetchCountInvitedUsers$ = createEffect(() => this.actions$.pipe(
    ofType<UsersStoreActions.FetchCountInvitedUsers>(UsersStoreActions.ActionTypes.FETCH_COUNT_INVITED_USERS),
    switchMap((action: UsersStoreActions.FetchCountInvitedUsers) =>
      this.apiClient.get<number>('/InvitedUsers/count').pipe(
        map((a) => new SetCountInvitedUsers(a)),
        catchError(err=>this.convertCatchError(err))
      )
    )
  ));

  fetchUserProperties$ = createEffect(() => this.actions$.pipe(
    ofType<UsersStoreActions.FetchUserProperties>(UsersStoreActions.ActionTypes.FETCH_USER_PROPERTIES),
    switchMap((action: UsersStoreActions.FetchUserProperties) =>
      this.apiClient.get<UserPropertyView[]>('/Users/current/properties').pipe(
        map((a) => new SetUserProperties(a)),
        catchError(err=>this.convertCatchError(err))
      )
    )
  ));

  updateUserTwitterProperty$ = createEffect(() => this.actions$.pipe(
    ofType<UsersStoreActions.UpdateUserTwitter>(UsersStoreActions.ActionTypes.UPDATE_USER_TWITTER),
    switchMap((action: UsersStoreActions.UpdateUserTwitter) =>
      this.apiClient.post('/Users/current/properties/twitter', {value: action.value}).pipe(
        map((a) => new FetchUserProperties()),
        catchError(err=>this.convertCatchError(err))
      )
    )
  ));

  updateUserTelegramProperty$ = createEffect(() => this.actions$.pipe(
    ofType<UsersStoreActions.UpdateUserTelegram>(UsersStoreActions.ActionTypes.UPDATE_USER_TELEGRAM),
    switchMap((action: UsersStoreActions.UpdateUserTelegram) =>
      this.apiClient.post('/Users/current/properties/telegram', {value: action.value}).pipe(
        map((a) => new FetchUserProperties()),
        catchError(err=>this.convertCatchError(err))
      )
    )
  ));


  private convertCatchError(err){
    return of(new SetErrors(err.error?.replace('Error from handler', '') ?? err.message ?? 'No error message'));
  };


  private getIdsByInvitedUsersChainedResult(payload: InvitedWalletsChainedResult): string[] {
    if ((payload.wallets == null || payload.wallets.length == 0) || ((payload.invitedWalletsByHashAddress == null || payload.invitedWalletsByHashAddress.length == 0) && payload.wallets?.length > 0))
      return [...payload.wallets, payload.hashWallet];
    else {
      let result = [...payload.wallets, payload.hashWallet];

      payload.invitedWalletsByHashAddress?.map(a => this.getIdsByInvitedUsersChainedResult(a)).forEach((a) => {
        if (a?.length > 0)
          result.push(...a);
      });
      return result.filter(
        (id, i, arr) => arr.findIndex(t => t === id) === i
      );
    }
  }
}
