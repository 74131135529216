import { NgModule } from '@angular/core';
import { InvitedUsersStoreModule } from '@app/_stores/invited-users/invited-users.store.module';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { tryCatchReducer } from '@app/_stores/tryCatchReducer';
import { UsersStoreModule } from '@app/_stores/users';

export const metaReducers: MetaReducer<any>[] = [tryCatchReducer];

@NgModule({
  declarations: [],
  imports: [
    InvitedUsersStoreModule,
    UsersStoreModule,
    StoreModule.forRoot({}, {metaReducers}),
    EffectsModule.forRoot([])
  ]
})
export class AppStoreModule { }
