import { SwaggerException } from '../../../../../_api/IdentityClient';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

export abstract class BaseChangeEmailConfirm<T> {

  private readonly snackBarMessage = 'An email with the verification code was sent to the specified e-mail. Check your email.';

  isLoading = new BehaviorSubject(false);
  error = '';

  protected constructor(public dialogRef: MatDialogRef<T>,
                        protected snackBar: MatSnackBar) {
  }

  protected abstract confirmChangEmail(confirmationCode: { confirmationCode: string, confirmationCodeNew?: string } | string): Observable<void>;

  protected abstract resendConfirmationCodeForChangeEmail(): Observable<void>;

  changeEmail(confirmationCodes: { confirmationCode: string, confirmationCodeNew?: string } | string): void {
    this.isLoading.next(true);
    let v2 = (<any>confirmationCodes) != null && (<any>confirmationCodes).v2;
    this.confirmChangEmail(confirmationCodes)
      .subscribe(() => {
        this.isLoading.next(false);
        this.dialogRef.close(true);
      }, error => {
        this.isLoading.next(false);
        if (SwaggerException.isSwaggerException(error)) {
          this.error = error.response;
        }
        else {
          this.error = error;
        }
      });
  }

  resendConfirmationCode(): void {
    this.isLoading.next(true);
    this.resendConfirmationCodeForChangeEmail()
      .subscribe(() => {
        this.isLoading.next(false);
        this.snackBar.open(this.snackBarMessage, '', {
          duration: 5000,
          horizontalPosition: 'left',
          panelClass: 'snack-notification'
        });
      }, error => {
        this.isLoading.next(false);
        if (SwaggerException.isSwaggerException(error)) {
          this.error = error.response;
        }
        else {
          this.error = error;
        }
      });
  }
}
