import { Injectable, InjectionToken } from "@angular/core";
import { AppHeadersService } from '../headers/app-headers.service';
import { EnvService } from '@services/env/env.service';

const configTokenName = 'CONFIG';
export const APP_CONFIG = new InjectionToken<AppConfigService>(configTokenName);

@Injectable()
export class AppConfigService {
  hosts: EnvService;
  headers: AppHeadersService;
}
