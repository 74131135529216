import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UpButtonService {
    
    private visibleSbj = new BehaviorSubject<boolean>(false);
    public isVisible = this.visibleSbj.asObservable();

    setVisible(state: boolean) {
        this.visibleSbj.next(state);
    }
}