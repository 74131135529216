import {Component} from '@angular/core';
import {AuthenticationService} from '../../../../../../../_services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../../../../../environments/environment';
import {OAuthSystem} from '../../../../../../../_api/IdentityClient';
import {MatDialog} from '@angular/material/dialog';
import {PopupSocialAuthBase} from '../PopupSocialAuthBase';

@Component({
  selector: 'app-linkedin-auth',
  templateUrl: './linkedin-auth.component.pug',
  styleUrls: ['./linkedin-auth.component.sass']
})
export class LinkedinAuthComponent extends PopupSocialAuthBase {
  constructor(authenticationService: AuthenticationService,
              router: Router,
              route: ActivatedRoute,
              dialog: MatDialog) {
    super(authenticationService, router, route, dialog);
  }

  protected getPopupUri(): string {
    return `${environment.api.identity}/api/authorization/linkedin`;
  }

  protected getOAuthRequestSystem(): OAuthSystem {
    return OAuthSystem.LinkedIn;
  }
}
