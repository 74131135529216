import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './modules/material/module';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { UpBtnComponent } from './components/up-btn/up-btn.component';
import { environment } from '../environments/environment';
import { API_IDENTITY_URL, IdentityClient } from '@api/IdentityClient';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { SocialAuthConfig } from './_config/social-auth-service.config';
import { API_SOCIAL_NETWORK_URL, SocialNetworkClient } from '@api/SocialNetworkClient';
import { SidebarModule } from './modules/lazy/+dashboard/sidebar/sidebar.module';
import { AuthModule } from './modules/lazy/+auth/auth.module';
import { PresaleBannerComponent } from './components/presale-banner/presale-banner.component';
import { API_BEEQB_API_URL, BeeqbClient } from '@api/BeeqbClient';
import { CookieService } from '@services/cookies/cookie.service';
import { InviteCodeCookiesService } from '@services/cookies/invite-code-cookies.service';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { AppStoreModule } from '@app/_stores/app-store.module';
import { EnvServiceProvider } from '@services/env/env.service.provider';
import { AppHeadersServiceProvider } from '@services/headers/app-headers.service.provider';
import { AppConfigServiceProvider } from '@services/config/app-config.service.provider';
import { AppConfigProvider } from '@services/config/app-config.provider';
import { ApiClient } from '@services/api/api-client.service';
import { AdminApiClient } from '@services/api/admin-api-client.service';
import { MatSliderModule } from '@angular/material/slider';
import { SupportModule } from './components/support/support.module';

// localization i18n
// import { httpLanguageLoader } from '@api/http-language-loader';
// import {
//   TRANSLOCO_CONFIG,
//   TranslocoConfig,
//   TranslocoModule,
// } from '@ngneat/transloco';

export function getSocialAuthServiceConfig() {
  return SocialAuthConfig.Configs;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UpBtnComponent,
    PresaleBannerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    AppRoutingModule,
    SocialLoginModule,
    SidebarModule,
    AuthModule,
    AppStoreModule,
    MatSliderModule,
    SupportModule
    //  TranslocoModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: API_IDENTITY_URL, useValue: environment.api.identity},
    {provide: API_SOCIAL_NETWORK_URL, useValue: environment.api.socialNetwork},
    {provide: API_BEEQB_API_URL, useValue: environment.api.beeqb},
    {provide: 'SocialAuthServiceConfig', useValue: SocialAuthConfig.Configs},
    ApiClient,
    AdminApiClient,
    AppConfigProvider,
    EnvServiceProvider,
    AppHeadersServiceProvider,
    AppConfigServiceProvider,
    CookieService,
    InviteCodeCookiesService,
    IdentityClient,
    SocialNetworkClient,
    BeeqbClient,
    // httpLanguageLoader,
    // {
    //   provide: TRANSLOCO_CONFIG,
    //   useValue: {
    //     availableLangs: ['en'],
    //     defaultLang: 'en',
    //   } as TranslocoConfig
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
