import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxFileDropEntry } from "ngx-file-drop";
import { Observable } from "rxjs";

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.pug',
  styleUrls: ['./file-drop.component.sass']
})
export class FileDropComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  @Input() public multiple = true;
  @Input() required = false;
  @Input() attachments: any[];
  @Input() clearAttachments$: Observable<void>;
  @Input() openDialog$: Observable<void>;
  @Input() color: 'white' | 'default' = 'default';
  @Input() type: 'support-attachment' | 'default' = 'default';

  @Output() public filesChanged: EventEmitter<NgxFileDropEntry[]> = new EventEmitter<NgxFileDropEntry[]>();
  @Output() eventTouched: EventEmitter<any> = new EventEmitter<any>();
  ///extensions of files in format .ext
  @Input() fileExtensions: string[] | string;
  @Input() description: string = 'Attach file';
  @Input() height = '50px';
  attachFileIcon: string = 'attach_file';
  @Input() readonly: boolean = false;

  get lineHeight() {
    return (+this.height.replace('px', '') - 1) + 'px';
  }

  get acceptedExtensions(): string {
    //@ts-ignore
    return this.fileExtensions;
  }

  constructor() {
  }

  private setFiles(files: NgxFileDropEntry[] | null | undefined) {
    this.files = files;
    this.emitFileEvent();
  }

  ngOnInit(): void {
    if (this.required && this.description?.length > 0)
      this.description += '*';

    if (this.attachments?.length > 0)
      this.setFiles(this.attachments.map(a => new NgxFileDropEntry(a.fileName, {
        isFile: true,
        name: a.fileName,
        isDirectory: false
      })));
    this.clearAttachments$?.subscribe(_ => this.setFiles([]));

    this.openDialog$.subscribe(a => {

    })
  }

  public dropped(event: NgxFileDropEntry[]) {
    if (this.files?.length > 0)
      this.files = [...this.files, ...event];
    else
      this.files = event
    this.emitFileEvent();
  }

  public deleteItem(item: NgxFileDropEntry) {
    this.setFiles(this.files.filter(a => a != item));
  }

  private emitFileEvent() {
    this.filesChanged.emit(this.files);
  }
}
