<div class="sidebar sticky-block" [ngClass]="isMobile ? 'mobile' : ''"><div class="nav"><ul><li><a [ngClass]="{'current-page': isActive('/profile')}" [routerLink]="['/profile']"><svg><use xlink:href="../../../assets/svg/icons/menu/my_page.svg#Icons/menu/my_page"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/mobile/my_page.svg#Icons/menu/my_page"></use></svg><span>My Profile</span></a></li><li><a [ngClass]="{'current-page': isActive('/')}" [routerLink]="['/']"><svg><use xlink:href="../../../assets/svg/icons/menu/wallets.svg#Icons/menu/wallets"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/mobile/wallets.svg#Icons/menu/wallets"></use></svg><span>Wallets</span></a></li><li><a [ngClass]="{'current-page': isActive('/contracts')}" [routerLink]="['/contracts']"><svg><use xlink:href="../../../assets/svg/icons/menu/contracts.svg#Icons/contract_ok"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/mobile/contracts.svg#Icons/contract_ok"></use></svg><span>Contracts</span></a></li><!--lia([ngClass]="{'current-page': isActive('/')}", [routerLink]="['/']")
  svg
    use(xlink:href="../../../assets/svg/icons/menu/transactions.svg#Icons/menu/transactions")
  span Transactions--><li><a [ngClass]="{'current-page': isActive('/consiliums')}" [routerLink]="['/consiliums']"><svg><use xlink:href="../../../assets/svg/icons/menu/consiliums.svg#Icons/menu/consiliums"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/mobile/consiliums.svg#Icons/menu/consiliums"></use></svg><span>Consiliums</span></a></li><!-- temporary disabled in scope of https://ubikiri.atlassian.net/browse/UBXD-206--><!--li--><!--  a([ngClass]="{'current-page': isActive('/contractors')}", [routerLink]="['/contractors']")--><!--    svg--><!--      use(xlink:href="../../../assets/svg/icons/menu/contractors.svg#Icons/menu/contractors")--><!--    svg.mobile--><!--      use(xlink:href="../../../assets/svg/icons/menu/mobile/contractors.svg#Icons/menu/contractors")--><!--    span Contractors--><!--lia([ngClass]="{'current-page': isActive('/help')}", [routerLink]="['/help']")
  svg
    use(xlink:href="../../../assets/svg/icons/menu/messages.svg#Icons/menu/messages")
  span Messages--><li><a [ngClass]="{'current-page': isActive('/feed')}" [routerLink]="['/feed']"><svg><use xlink:href="../../../assets/svg/icons/menu/news.svg#Icons/menu/news"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/mobile/news.svg#Icons/menu/news"></use></svg><span>Feed</span></a></li><li><a [ngClass]="{'current-page': isActive('/sandbox')}" [routerLink]="['/sandbox']"><svg><use xlink:href="../../../assets/svg/icons/menu/sandbox.svg#Icons/menu/sandbox"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/sandbox.svg#Icons/menu/sandbox"></use></svg><span>Sandbox</span></a></li><li><a [ngClass]="{'current-page': isActive('/explorer')}" [routerLink]="['/explorer']"><svg><use xlink:href="../../../assets/svg/icons/menu/explorer.svg#Icons/menu/explorer"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/mobile/explorer.svg#Icons/menu/explorer"></use></svg><span>Explorer</span></a></li><li><a [ngClass]="{'current-page': isActive('/oracles')}" [routerLink]="['/oracles']"><svg><use xlink:href="../../../assets/svg/icons/menu/oracle.svg#Icons/menu/oracle"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/oracle.svg#Icons/menu/oracle"></use></svg><span>Oracles</span></a></li><!--lia([ngClass]="{'current-page': isActive('/')}", [routerLink]="['/']")
  svg
    use(xlink:href="../../../assets/svg/icons/menu/settings.svg#Icons/settings")
  span Settings
--><div class="divider"></div><li><a [ngClass]="{'current-page': isActive('/applications')}" [routerLink]="['/applications']"><svg><use xlink:href="../../../assets/svg/icons/menu/applications.svg#Icons/menu/applications"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/applications.svg#Icons/menu/applications"></use></svg><span>Applications</span></a></li><ul class="bordered" *ngIf="isMobile"><li><a [ngClass]="{'current-page': isActive('/settings-account')}" [routerLink]="['/settings-account']"><svg><use xlink:href="../../../assets/svg/icons/menu/settings.svg#Icons/settings"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/mobile/settings.svg#Icons/settings"></use></svg><span>Settings Profile</span></a></li><li><a [ngClass]="{'current-page': isActive('/help')}" [routerLink]="['/help']"><svg><use xlink:href="../../../assets/svg/icons/menu/help.svg#Icons/menu/help"></use></svg><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/mobile/help.svg#Icons/menu/help"></use></svg><span>Help</span></a></li></ul><ul class="bordered" *ngIf="isMobile"><li><a (click)="authenticationService.logout()"><svg class="mobile"><use xlink:href="../../../assets/svg/icons/menu/mobile/logout.svg#Icons/logout"></use></svg><span>Log Out</span></a></li></ul></ul></div></div>