import {Component} from '@angular/core';

@Component({
  selector: 'app-restore-complete',
  templateUrl: './restore-complete.component.pug',
  styleUrls: ['./restore-complete.component.sass']
})
export class RestoreCompleteComponent {

  constructor() {
  }

}
