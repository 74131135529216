import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CustomValidators {

  public static namedPatternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  public static greaterThanValidator(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null || min === null) {
        return null;
      }
      const value = parseFloat(control.value);
      return !isNaN(value) && value <= min ? {'greaterThan': {'min': min, 'actual': control.value}} : null;
    };
  }
}

export function passwordMatchValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({passwordsNotMatch: true});
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function walletsAddressSimpleValidator(controlAddressFrom: string, controlAddressTo: string) {
  return (formGroup: FormGroup) => {
    const addressFrom = formGroup.controls[controlAddressFrom];
    const addressTo = formGroup.controls[controlAddressTo];

    if (addressTo.errors && !addressTo.errors.walletsAddressInvalid) {
      return;
    }

    // if (!addressFrom.value || !addressTo.value || addressFrom.value.slice(0, 2) !== addressTo.value.slice(0, 2) || (addressFrom.value === addressTo.value)) {
    if (!addressFrom.value || !addressTo.value || addressFrom.value.slice(0, 2) !== addressTo.value.slice(0, 2)) {
      addressTo.setErrors({walletsAddressInvalid: true});
    } else {
      addressTo.setErrors(null);
    }
  };
}
