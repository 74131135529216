import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login-registration',
  templateUrl: './login-registration.component.pug',
  styleUrls: ['./login-registration.component.sass']
})
export class LoginRegistrationComponent implements OnInit {
  isLogin = false;
  isMobileWelcome = true;
  loading = new Subject();
  inviteCode: string;

  constructor(private route: ActivatedRoute) {
  }

  toggleAuth(isLogin: boolean) {
    this.isLogin = !isLogin;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(data => {
      this.isLogin = !!localStorage.getItem('email') || (data.email && data.token) || data.isLogin;
      this.isMobileWelcome = !localStorage.getItem('email') || !(data.email && data.token) && !data.isLogin;
      if (data.inviteCode)
        this.inviteCode = decodeURIComponent(data.inviteCode);
    });
  }
}
