import { APP_CONFIG, AppConfigService } from './app-config.service';
import { environment } from '../../../environments/environment';

export const AppConfigProvider = {
  provide: APP_CONFIG,
  useValue: <AppConfigService>{
    headers: {values: environment.headers},
    hosts: {
      apiUrl: environment.api.socialNetwork,
      adminUrl: environment.api.identity,
      chatUrl: environment.api.chat
    }
  }
};
