import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {Observable, of, pipe, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export const API_BEEQB_API_URL = new InjectionToken<string>('API_BEEQB_API_URL');

@Injectable()
export class BeeqbClient {
  private http: HttpClient;
  private baseUrl: string;
  private headersOption = new HttpHeaders({
    'Content-Type': 'application/json'
    // 'X-Access-Token': localStorage.getItem('access_token')
  });

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BEEQB_API_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : '';
  }

  getTrades(symbol: string, limit: number, since: number): Observable<TradeModel[]> {
    let params = `symbol=${symbol}`;
    if (since) {
      params += `&since=${since}`;
    }
    if (limit) {
      params += `&limit=${limit}`;
    }
    // return of([
    //   {
    //     'id': 1,
    //     'symbol': 'BEE/ETH',
    //     'timestamp': 1593765675.457562,
    //     'datetime': new Date('2020-07-03T06:23:12.982955'),
    //     'order': 1,
    //     'type': 'limit',
    //     'side': 'buy',
    //     'price': '0.00100000',
    //     'amount': '4.00000000',
    //     'cost': '0.00400000',
    //     'info': {
    //       'globalTradeID': 1,
    //       'tradeID': 1,
    //       'date': new Date('2020-07-03T06:23:12.982955'),
    //       'type': 'buy',
    //       'rate': '0.00100000',
    //       'amount': '4.00000000',
    //       'total': '0.00400000'
    //     }
    //   },
    //   {
    //     'id': 4,
    //     'symbol': 'BEE/ETH',
    //     'timestamp': 1593952652.550877,
    //     'datetime': new Date('2020-07-05T12:37:32.480564'),
    //     'order': 4,
    //     'type': 'limit',
    //     'side': 'buy',
    //     'price': '0.00020000',
    //     'amount': '100.00000000',
    //     'cost': '0.02000000',
    //     'info': {
    //       'globalTradeID': 2,
    //       'tradeID': 4,
    //       'date': new Date('2020-07-05T12:37:32.480564'),
    //       'type': 'buy',
    //       'rate': '0.00020000',
    //       'amount': '100.00000000',
    //       'total': '0.02000000'
    //     }
    //   },
    //   {
    //     'id': 9,
    //     'symbol': 'ETH/BEE',
    //     'timestamp': 1594292091.107507,
    //     'datetime': new Date('2020-07-09T10:54:51.073522'),
    //     'order': 9,
    //     'type': 'limit',
    //     'side': 'buy',
    //     'price': '0.00070000',
    //     'amount': '200.00000000',
    //     'cost': '0.14000000',
    //     'info': {
    //       'globalTradeID': 4,
    //       'tradeID': 9,
    //       'date': new Date('2020-07-09T10:54:51.073522'),
    //       'type': 'buy',
    //       'rate': '0.00070000',
    //       'amount': '200.00000000',
    //       'total': '0.14000000'
    //     }
    //   },
    //   {
    //     'id': 10,
    //     'symbol': 'BEE/ETH',
    //     'timestamp': 1594373019.442656,
    //     'datetime': new Date('2020-07-10T09:23:39.305397'),
    //     'order': 10,
    //     'type': 'limit',
    //     'side': 'sell',
    //     'price': '0.00070000',
    //     'amount': '55.00000000',
    //     'cost': '0.03850000',
    //     'info': {
    //       'globalTradeID': 5,
    //       'tradeID': 10,
    //       'date': new Date('2020-07-10T09:23:39.305397'),
    //       'type': 'sell',
    //       'rate': '0.00070000',
    //       'amount': '55.00000000',
    //       'total': '0.03850000'
    //     }
    //   },
    //   {
    //     'id': 11,
    //     'symbol': 'ETH/BEE',
    //     'timestamp': 1594373052.641221,
    //     'datetime': new Date('2020-07-10T09:24:12.599922'),
    //     'order': 11,
    //     'type': 'limit',
    //     'side': 'buy',
    //     'price': '0.00070000',
    //     'amount': '55.00000000',
    //     'cost': '0.03850000',
    //     'info': {
    //       'globalTradeID': 6,
    //       'tradeID': 11,
    //       'date': new Date('2020-07-10T09:24:12.599922'),
    //       'type': 'buy',
    //       'rate': '0.00070000',
    //       'amount': '55.00000000',
    //       'total': '0.03850000'
    //     }
    //   }
    // ]);
    return this.http.get<TradeModel[]>(this.baseUrl + `/fetch_trades?${params}`, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }

  getTradeChartData(symbol: string, limit: number, since: number, timeframe: string): Observable<TradeChartDataModel[]> {
    let params = `symbol=${symbol}`;

    if (since) {
      params += `&since=${since}`;
    }
    if (timeframe) {
      params += `&timeframe=${timeframe}`;
    } else {
      params += '&timeframe=60';
    }
    if (limit) {
      params += `&limit=${limit}`;
    }

    // return of([
    //   {
    //     'timestamp': 1594396320000,
    //     'start': 183.84,
    //     'max': 186.40,
    //     'min': 183.52,
    //     'stop': 186.52,
    //     'volume': 0
    //   },
    //   {
    //     'timestamp': 1594396260000,
    //     'start': 182.84,
    //     'max': 182.40,
    //     'min': 183.52,
    //     'stop': 182.52,
    //     'volume': 0
    //   },
    //   {
    //     'timestamp': 1594396200000,
    //     'start': 187.84,
    //     'max': 188.40,
    //     'min': 183.52,
    //     'stop': 187.52,
    //     'volume': 0
    //   },
    //   {
    //     'timestamp': 1594396140000,
    //     'start': 182.84,
    //     'max': 189.49,
    //     'min': 183.52,
    //     'stop': 181.52,
    //     'volume': 0
    //   },
    //   {
    //     'timestamp': 1594396080000,
    //     'start': 181.84,
    //     'max': 182.49,
    //     'min': 183.52,
    //     'stop': 182.52,
    //     'volume': 0
    //   }
    // ]);

    return this.http.get<TradeChartDataModel[]>(this.baseUrl + `/fetch_ohlcv?${params}`, {headers: this.headersOption})
      .pipe(
        pipe(map(result => result.sort((a, b) => a.timestamp - b.timestamp))),
        catchError(this.handleError)
      );
  }

  getOpenOrders(symbol: string, limit: number, since: number): Observable<OrderModel[]> {
    let params = `symbol=${symbol}`;

    if (since) {
      params += `&since=${since}`;
    }
    if (limit) {
      params += `&limit=${limit}`;
    }

    // return of([
    //   {
    //     'id': '51',
    //     'timestamp': 1594798522000,
    //     'datetime': new Date('2020-07-15T07:35:22.677386'),
    //     'symbol': 'BEE/ETH',
    //     'type': 'limit',
    //     'side': 'buy',
    //     'price': 3,
    //     'amount': 5,
    //     'cost': 0,
    //     'filled': 0,
    //     'remaining': 5,
    //     'fee': {
    //       'currency': 'ETH',
    //       'cost': '0.00000000',
    //       'rate': '0.0001'
    //     },
    //     'trades': [],
    //     'status': 'wait'
    //   },
    //   {
    //     'id': '52',
    //     'timestamp': 1594798620000,
    //     'datetime': new Date('2020-07-15T07:37:00.726901'),
    //     'symbol': 'BEE/ETH',
    //     'type': 'limit',
    //     'side': 'sell',
    //     'price': 1,
    //     'amount': 50,
    //     'cost': 0,
    //     'filled': 0,
    //     'remaining': 50,
    //     'fee': {
    //       'currency': 'ETH',
    //       'cost': '0.00000000',
    //       'rate': '0.0001'
    //     },
    //     'trades': [],
    //     'status': 'wait'
    //   }
    // ]);

    return this.http.get<OrderModel[]>(this.baseUrl + `/fetch_open_orders?${params}`, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }

  getGroupedOrders(symbol: string): Observable<OrderBookModel> {
    // return of({
    //   'bids': [
    //     [
    //       3.3,
    //       5,
    //       1
    //     ]
    //   ],
    //   'asks': [
    //     [
    //       5.2,
    //       4,
    //       0
    //     ]
    //   ],
    //   'datetime': new Date('2020-07-21T14:25:20.580')
    // });

    return this.http.get<OrderBookModel>(this.baseUrl + `/fetch_grouped_order_book?symbol=${symbol}`, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }

  cancelOrder(symbol: string, id: number): Observable<ResponseModel> {
    const httpOptions = {
      headers: this.headersOption,
      body: {
        'id': id.toString(),
        'symbol': symbol
      }
    };
    return this.http.delete<ResponseModel>(this.baseUrl + '/order/cancel', httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createUser(): Observable<string> {
    return this.http.post<string>(this.baseUrl + `/user/create`, null, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }

  createOrder(order: OrderDtoModel): Observable<void> {
    return this.http.post<void>(this.baseUrl + `/order`, order, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }


  withdraw(obj: WithdrawDtoModel): Observable<void> {
    return this.http.post<void>(this.baseUrl + `/user/withdraw`, obj, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }

  getBalance(): Observable<BalanceModel> {
    return this.http.get<BalanceModel>(this.baseUrl + `/balance`, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }

  getCurrencies(): Observable<CurrenciesModel[]> {
    return this.http.get<CurrenciesModel[]>(this.baseUrl + `/currencies`, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }

  getDepositAddress(currency: string): Observable<DepositAddressModel> {
    return this.http.get<DepositAddressModel>(this.baseUrl + `/user/depAddress?currency=${currency}`, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }
  getTokenNetwork(token: string): Observable<TokenNetworkModel> {
    return this.http.get<TokenNetworkModel>(this.baseUrl + `/tokenNetwork?token=${token}`, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }
  getSymbols(): Observable<SymbolsModel[]> {
    return this.http.get<SymbolsModel[]>(this.baseUrl + `/symbols`, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }

  getTickers(): Observable<TickersModel[]> {
    return this.http.get<TickersModel[]>(this.baseUrl + `/fetch_tickers`, {headers: this.headersOption})
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else  {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.error || error.message}`;
    }
    return throwError(errorMessage);
  }
}
export interface TokenNetworkModel {
  network: string;
}
export interface OrderDtoModel {
  symbol: string;
  type: string;
  amount: number;
  price: string;
  side: string;
}

export interface DepositAddressModel {
  address: string;
  network: string;
}

export interface WithdrawDtoModel {
  currency: string;
  amount: number;
  address: string;
}

export interface TickersModel {
  symbol: string;
  info: TickersInfoModel;
}

export interface TickersInfoModel {
  id: number;
  trade_id: number;
  price: number;
  volume: number;
  bid: number;
  ask: number;
  percentChange: number;
  time: string | Date;
  last: number;
  high24: number;
  low24: number;
  baseVolume: number;
  quoteVolume: number;
}

export interface SymbolsModel {
  id: string;
  symbol: string;
  base: string;
  quote: string;
  baseId: string;
  quoteId: string;
  active: boolean;
}

export interface CurrenciesModel {
  id: string;
  code: string;
  precision: number;
}

export interface BalanceModel {
  info: BalanceInfoModel[];
  free: BalanceFreeModel;
  used: BalanceUsedModel;
  total: BalanceTotalModel;
}

export interface BalanceFreeModel {
  [key: string]: number;
}

export interface BalanceUsedModel {
  [key: string]: number;
}

export interface BalanceTotalModel {
  [key: string]: number;
}

export interface BalanceInfoModel {
  currency: string;
  available: number;
}

export interface TradeModel {
  id: number;
  symbol: string;
  timestamp: number;
  datetime: Date;
  order: number;
  type: string;
  side: string;
  price: string;
  amount: string;
  cost: string;
  info: TradeInfoModel;
}

export interface TradeInfoModel {
  globalTradeID: number;
  tradeID: number;
  date: Date;
  type: string;
  rate: string;
  amount: string;
  total: string;
}

export interface TradeChartDataModel {
  timestamp: number;
  start: number;
  max: number;
  min: number;
  end: number;
  volume: number;
}

export interface OrderModel {
  id: string;
  timestamp: number;
  datetime: Date;
  symbol: string;
  type: string;
  side: string;
  price: number;
  amount: number;
  cost: number;
  filled: number;
  remaining: number;
  fee: FeeModel;
  trades: any[];
  status: string;
}

export interface FeeModel {
  currency: string;
  cost: string;
  rate: string;
}

export interface ResponseModel {
  info: ResponseInfo;
  timestamp: null;
}

export interface ResponseInfo {
  result: boolean;
  error: string;
}

export interface OrderBookModel {
  bids: Array<number[]>;
  asks: Array<number[]>;
  datetime: Date;
}

export const tokenMapper = {
  LAW: 'UBX',
  LSE: 'UBXE',
  CDR: 'UBCDR',
  WAF: 'UBWF',
  MMM: 'UBXM',
  ESC: 'UBESC',
  VGS: 'UBXCV',
  CIF: 'UBINF',
  SIN: 'UBSIN',
  TKS: 'UBX4T',
  CDF: 'UBPAY',
  LFB: 'UBXLB',
  STK: 'UBSMK'
};
