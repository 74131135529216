import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthRoutingModule} from './auth-routing.module';
import {LoginComponent} from './components/login-registration/login/login.component';
import {MaterialModule} from '../../material/module';
import {RegistrationComponent} from './components/login-registration/registration/registration.component';
import {RestoreComponent} from './components/restore/restore.component';
import {ReactiveFormsModule} from '@angular/forms';
import {RegistrationCompleteComponent} from './components/registration-complete/registration-complete.component';
import {RestoreCompleteComponent} from './components/restore-complete/restore-complete.component';
import {RestoreConfirmComponent} from './components/restore-confirm/restore-confirm.component';
import {RestoreConfirmCompleteComponent} from './components/restore-confirm-complete/restore-confirm-complete.component';
import {RegistrationSuccessComponent} from './components/registration-success/registration-success.component';
import {LoginRegistrationComponent} from './components/login-registration/login-registration.component';
import {LoginErrorComponent} from './components/login-registration/dialogs/login-error/login-error.component';
import {FacebookAuthComponent} from './components/login-registration/components/facebook-auth/facebook-auth.component';
import {LinkedinAuthComponent} from './components/login-registration/components/linkedin-auth/linkedin-auth.component';
import {GoogleAuthComponent} from './components/login-registration/components/google-auth/google-auth.component';
import {TelegramAuthComponent} from './components/login-registration/components/telegram-auth/telegram-auth.component';
import {TelegramEmailComponent} from './components/login-registration/dialogs/telegram-email/telegram-email.component';
import {InputMaxLengthModule} from '../../../_helpers/directives/input-max-length/input-max-length.module';
import {AppleAuthComponent} from './components/login-registration/components/apple-auth/apple-auth.component';
import { ConfirmationComponent } from './components/login-registration/confirmation/confirmation.component';
import { TwoFaReminderComponent } from './components/login-registration/dialogs/two-fa-reminder/two-fa-reminder.component';
import { TelegramWidgetComponent } from './components/login-registration/components/telegram-widget/telegram-widget.component';
import {AngularTelegramLoginWidgetComponent} from '@app/modules/lazy/+auth/components/login-registration/telegram-login-widget/telegram-login-widget';
import { AuthIconsComponent } from './components/login-registration/components/auth-icons/auth-icons.component';
import { AuthIconComponent } from './components/login-registration/components/auth-icon/auth-icon.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {SupportModule} from '../../../components/support/support.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    MaterialModule,
    InputMaxLengthModule,
    FlexLayoutModule,
    SupportModule
  ],
  declarations: [
    LoginComponent,
    RegistrationComponent,
    RestoreComponent,
    RegistrationCompleteComponent,
    RestoreCompleteComponent,
    RestoreConfirmComponent,
    RestoreConfirmCompleteComponent,
    RegistrationSuccessComponent,
    LoginRegistrationComponent,
    LoginErrorComponent,
    FacebookAuthComponent,
    LinkedinAuthComponent,
    GoogleAuthComponent,
    TelegramAuthComponent,
    TelegramEmailComponent,
    AppleAuthComponent,
    ConfirmationComponent,
    TwoFaReminderComponent,
    TelegramWidgetComponent,
    AngularTelegramLoginWidgetComponent,
    AuthIconsComponent,
    AuthIconComponent
  ],
  exports: [
    LoginComponent,
    LoginErrorComponent,
    TelegramWidgetComponent,
    AngularTelegramLoginWidgetComponent
  ],
  entryComponents: [
    LoginErrorComponent,
    TelegramEmailComponent,
    TwoFaReminderComponent
  ]
})
export class AuthModule {
}
