import { Component, Input } from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../../_services/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.pug',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent {

  @Input() isMobile: boolean;

  constructor(private router: Router,
              public authenticationService: AuthenticationService) { }

  isActive(path: string): boolean {
    const reg = new RegExp('^\\/profile\\/[1-9]\\d{0,5}$');
    if (reg.test(this.router.url) && path === '/contractors') {
      return true;
    }
    if (reg.test(this.router.url) && path.includes('profile')) {
      return false;
    }
    if (path === '/') {
      return this.router.url === path || this.router.url.includes('wallet');
    }
    return this.router.url.includes(path);
  }
}
