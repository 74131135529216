import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './invited-users.state';
import { StoreTypes } from '../store-types';


const invitedUsersState = createFeatureSelector<State>(StoreTypes.invitedUsers);

export const getInvitedUsers = createSelector(invitedUsersState, a => a.invitedWallets);
export const getInvitedUsersAny = createSelector(invitedUsersState, a => a.invitedWallets?.wallets?.length > 0);
export const getIsLoading = createSelector(invitedUsersState, a => a.isLoading);
export const getErrors = createSelector(invitedUsersState, a => a.errors);
