import { FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";

export class NgxFileDropHelper {
  public static getFiles(...files: NgxFileDropEntry[]): File[] {
    let result: File[] = [];
    if (files?.length > 0) {
      for (const item of files) {
        let file = this.getFile(item);
        if (file)
          result.push(file);
      }
    }

    return result?.length > 0 ? result : null;
  }

  public static getFile(file: NgxFileDropEntry): File {
    if (file?.fileEntry?.isFile) {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      let fileResult: File = null;
      fileEntry.file((a) => {
        fileResult = a;
      })
      return fileResult;
    }
    return null;
  }
}
