import {Component} from '@angular/core';
import {EmailWithConfirmationActionRequest, ErrorResponse, IdentityClient} from '../../../../../_api/IdentityClient';
import {AuthConfig} from '../../../../../_config/auth.config';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-registration-complete',
  templateUrl: './registration-complete.component.pug',
  styleUrls: ['./registration-complete.component.sass']
})
export class RegistrationCompleteComponent {
  error: string;
  loading = new Subject();

  constructor(private iddentityClient: IdentityClient) {
  }

  sendEmail() {
    this.error = '';
    const email = localStorage.getItem('email');
    if (email == null) {
      this.error = 'errors.userwithemailnotfound';
      return;
    }

    this.loading.next(true);
    const request = new EmailWithConfirmationActionRequest({
      email,
      confirmationAction: AuthConfig.LoginConfirmationAction
    });

    this.iddentityClient.resendVerification(request)
      .subscribe(async () => {
          this.loading.next(false);
        },
        (error: ErrorResponse) => {
          this.error = error.message;
          this.loading.next(false);
        });
  }

  removeStorageItem() {
    localStorage.removeItem('email');
  }
}
