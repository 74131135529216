import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {of, throwError} from 'rxjs';
import {flatMap, first} from 'rxjs/operators';
import {AuthenticationService} from '../../../../../../../_services/authentication.service';
import {environment} from '../../../../../../../../environments/environment';
import {
  OAuthSystem,
  ErrorResponse,
  SwaggerException,
  IdentityClient
} from '../../../../../../../_api/IdentityClient';
import {TelegramEmailComponent} from '../../dialogs/telegram-email/telegram-email.component';
import {PopupSocialAuthBase} from '../PopupSocialAuthBase';
import {Base64} from 'js-base64';
import {TelegramWidgetComponent} from '../telegram-widget/telegram-widget.component';

@Component({
  selector: 'app-telegram-auth',
  templateUrl: './telegram-auth.component.pug',
  styleUrls: ['./telegram-auth.component.sass']
})
export class TelegramAuthComponent extends PopupSocialAuthBase {
  constructor(authenticationService: AuthenticationService,
              router: Router,
              route: ActivatedRoute,
              dialog: MatDialog,
              private identityClient: IdentityClient) {
    super(authenticationService, router, route, dialog);
  }

  protected getPopupUri(): string {
    return `${environment.api.identity}/api/authorization/telegram`;
  }

  protected getOAuthRequestSystem(): OAuthSystem {
    return OAuthSystem.Telegram;
  }

  auth() {
    this
      .dialog
      .open(TelegramWidgetComponent, {
        width: '440px'
      })
      .afterClosed()
      .subscribe(user => this.signIn(user));
  }

  signIn(user: any) {
    if (!user) {
      return;
    }

    this.onLoading.next(true);
    this.identityClient.checkTelegram(user.id)
      .pipe(first())
      .pipe(flatMap(data => {
        return data.isRegistered
          ? of('create')
          : this.dialog.open(TelegramEmailComponent, {
            width: '440px',
            maxWidth: '90vw'
          }).afterClosed();
      }))
      .pipe(flatMap(email => {
        if (!email) {
          return throwError('close');
        }
        const accessToken =  Base64.encode(JSON.stringify({
          email: email === 'create' ? '' : email,
          telegramUser: user
        }));
        return this.authenticationService.socialLogin(accessToken, OAuthSystem.Telegram);
      }))
      .pipe(flatMap(() => this.authenticationService.getUserData()))
      .subscribe(async () => {
          await this.router.navigateByUrl(this.returnUrl);
          this.onLoading.next(false);
        },
        (error: ErrorResponse | SwaggerException) => {
          if (SwaggerException.isSwaggerException(error)) {
            error = JSON.parse(error.response);
          }
          if (error.message) {
            this.showErrorDialog((<any>error.message).message || error.message);
          }
          this.onLoading.next(false);
        });
  }
}
