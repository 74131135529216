import Timeout = NodeJS.Timeout;
import {
  IdentityClient,
  ISignInRequest,
  ITwoFactorConfirmationRequest,
  JwtTokenResponse,
  OAuthRequest,
  OAuthSystem,
  RenewJwtTokenRequest,
  SignInRequest,
  SignInResponse,
  TwoFactorConfirmationRequest,
  UserResponse
} from '@api/IdentityClient';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SocialNetworkClient } from '@api/SocialNetworkClient';
import { InviteCodeCookiesService } from '@services/cookies/invite-code-cookies.service';
import { catchError, first, map } from 'rxjs/operators';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Injectable({providedIn: 'root'})
export class AuthenticationService {


  private isAuthenticatedSubj$: BehaviorSubject<string>;
  private authenticatedUserDataSubj$: BehaviorSubject<UserResponse>;
  public isAuthenticated$: Observable<string>;
  public token$: BehaviorSubject<JwtTokenResponse> = new BehaviorSubject<JwtTokenResponse>(null);
  public authenticatedUserData$: Observable<UserResponse>;

  private loginTimer: Timeout;
  private token: JwtTokenResponse;
  private currentUser;

  constructor(private identityClient: IdentityClient, private router: Router, private socialAuthService: SocialAuthService,
              private webApi: SocialNetworkClient, private inviteCookieService: InviteCodeCookiesService) {

    const currentUserStore = localStorage.getItem('currentUser');
    const currentUserDataStore = localStorage.getItem('currentUserData');

    const userFromStore = currentUserStore ? JSON.parse(currentUserStore) : null;
    const userDataFromStore = currentUserDataStore ? JSON.parse(currentUserDataStore) : null;

    this.isAuthenticatedSubj$ = new BehaviorSubject<string>(userFromStore);
    this.authenticatedUserDataSubj$ = new BehaviorSubject<UserResponse>(userDataFromStore);

    this.isAuthenticated$ = this.isAuthenticatedSubj$.asObservable();
    this.authenticatedUserData$ = this.authenticatedUserDataSubj$.asObservable();

    this.isAuthenticated$.subscribe(user => {
      if (user) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.setAccessToken(user);
      } else {
        localStorage.removeItem('currentUser');
      }
    });

    this.authenticatedUserData$.subscribe(user => {
      if (user) {
        localStorage.setItem('currentUserData', JSON.stringify(user));
      } else {
        localStorage.removeItem('currentUserData');
      }
    });

    this.checkCurrentToken();
  }

  getUserData(): Observable<UserResponse> {
    return this.identityClient.getCurrentUser()
      .pipe(map((user: UserResponse) => {
        if (user) {
          this.registerInviteCode(user.id);
          this.authenticatedUserDataSubj$.next(user);
        }
        return user;
      }));
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  setTokens(access: string, refresh: string) {
    this.setAccessToken(access);
    this.setRefreshToken(refresh);
  }

  setAccessToken(value: string) {
    return localStorage.setItem('access_token', value);
  }

  setRefreshToken(value: string) {
    return localStorage.setItem('refresh_token', value);
  }

  clearTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }

  isAuthenticated() {
    return this.getAccessToken() !== null;
  }

  login(username: string, password: string): Observable<SignInResponse> {
    return this.identityClient.authorizationWithTwoFa(new SignInRequest(
      <ISignInRequest>{
        email: username,
        password: password
      }
    ))
      .pipe(map((response: SignInResponse) => {
        // login successful if there's a jwt token in the response
        if (response.access_token) {
          this.setCurrentToken(response);
        }


        return response;
      }));

  }

  private registerInviteCode(userId) {
    const inviteCode = this.inviteCookieService.Get();
    if (inviteCode) {
      this.webApi.registerInviteCode(inviteCode, userId).toPromise().then();
    }
  }

  private setCurrentToken(token: JwtTokenResponse): JwtTokenResponse {
    this.token = token;
    const userDiff = this.getUserByAccessToken(token.access_token);

    this.currentUser = userDiff.user;
    if (this.loginTimer) {
      clearTimeout(this.loginTimer);
    }

    if (token.refresh_token) {
      this.loginTimer = setTimeout(() => this.renewToken(token.access_token, token.refresh_token), userDiff.diff / 2);
    }

    this.token$.next(token);
    this.isAuthenticatedSubj$.next(token.access_token);
    return token;
  }

  private async renewToken(access: string, refresh: string) {
    if (this.loginTimer) {
      clearTimeout(this.loginTimer);
    }
    return await firstValueFrom(this.identityClient.renewJwt(new RenewJwtTokenRequest({token: refresh}))
      .pipe(
        map((token) => {
          if (token && token.access_token) {
            return this.setCurrentToken(token);
          }
          return null;
        }),
        catchError(async (a, s) => {
          await this.logout();
          return null;
        })
      ));
  }

  confirm2Fa(token: string, code: string): Observable<string> {
    return this.confirm2FaInternal(this.identityClient.authorizationConfirmTwoFa(new TwoFactorConfirmationRequest(
      <ITwoFactorConfirmationRequest>{
        code: code,
        token: token
      }
    )));
  }

  confirm2FaWithRecoveryCode(token: string, code: string): Observable<string> {
    return this.confirm2FaInternal(this.identityClient.authorizationConfirmTwoFaRecovery(new TwoFactorConfirmationRequest(
      <ITwoFactorConfirmationRequest>{
        code: code,
        token: token
      }
    )));
  }

  private confirm2FaInternal(resp: Observable<SignInResponse>): Observable<string> {
    return resp.pipe(map((response: SignInResponse) => {
      // login successful if there's a jwt token in the response
      if (response.access_token) {
        this.isAuthenticatedSubj$.next(response.access_token);
      }
      return response.access_token;
    }));
  }

  private getUserByAccessToken(accessToken) {
    const obj = JSON.parse(atob(accessToken.split('.')[1]));
    const expDate = new Date(0);
    expDate.setUTCSeconds(obj.exp);
    if (expDate > new Date()) {
      const user = {
        id: this.parseGuid(obj.sid),
        firstName: obj.given_name,
        lastName: obj.family_name,
        email: obj.sub
      };

      const diff = expDate.getTime() - new Date().getTime();
      return {diff: diff, user: user};
    }
    return {};
  }

  private parseToken(accessToken, refreshToken): JwtTokenResponse {
    const obj = JSON.parse(atob(accessToken.split('.')[1]));
    const expDate = new Date(0);
    expDate.setUTCSeconds(obj.exp);
    const diff = expDate.getTime() - new Date().getTime();
    return new JwtTokenResponse({
      access_token: accessToken,
      refresh_token: refreshToken,
      expires_in: diff,
      issued: expDate
    });
  }


  private parseGuid(id: string) {
    const parts = [];
    parts.push(id.slice(0, 8));
    parts.push(id.slice(8, 12));
    parts.push(id.slice(12, 16));
    parts.push(id.slice(16, 20));
    parts.push(id.slice(20, 32));
    return parts.join('-');
  }

  socialLogin(accessToken: string, system: OAuthSystem): Observable<JwtTokenResponse> {
    return this.identityClient.socialAuthorizationWithRefreshToken(new OAuthRequest({
        accessToken: accessToken,
        system: system
      }
    )).pipe(map((token: JwtTokenResponse) => {
      // login successful if there's a jwt token in the response
      if (token.access_token) {
        this.isAuthenticatedSubj$.next(token.access_token);
        this.token$.next(token);
      }
      return token;
    }));
  }

  async logout(): Promise<any> {
    this.logoutWithoutRedirect();
    await this.router.navigate(['/auth/login-registration'], {queryParams: {returnUrl: this.router.routerState.snapshot.url}});
  }

  logoutWithoutRedirect() {
    this.socialAuthService.authState
      .pipe(first())
      .subscribe(async data => {
        if (!!data) {
          await this.socialAuthService.signOut();
        }
      }, err => console.log(err));

    this.isAuthenticatedSubj$.next(null);
    this.authenticatedUserDataSubj$.next(null);
    this.token$.next(null);
  }

  private checkCurrentToken() {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();

    if (!accessToken) {
      this.clearTokens();
      this.subscribeToken();
      this.logoutWithoutRedirect();
      return;
    }
    this.subscribeToken();
    const user = this.getUserByAccessToken(accessToken);
    if (user.diff <= 0) {
      this.renewToken(accessToken, refreshToken);
    } else {
      this.setCurrentToken(this.parseToken(accessToken, refreshToken));
    }
  }

  public async checkAccessToken() {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();

    if (!accessToken) {
      this.clearTokens();
      return;
    }
    const user = this.getUserByAccessToken(accessToken);
    if (user.diff <= 0) {
      return await this.renewToken(accessToken, refreshToken);
    }
  }


  private subscribeToken() {
    this.token$.subscribe((a) => {
      if (a && a.access_token) {
        localStorage.setItem('access_token', a.access_token);
        localStorage.setItem('refresh_token', a.refresh_token);
        return;
      }

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');

    });
  }
}

