import {Component} from '@angular/core';

@Component({
  selector: 'app-restore-confirm-complete',
  templateUrl: './restore-confirm-complete.component.pug',
  styleUrls: ['./restore-confirm-complete.component.sass']
})
export class RestoreConfirmCompleteComponent {

  constructor() {
  }

}
