import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import {AuthIconModel} from '../../../../../../../_models/External/AuthIconModel'

@Component({
  selector: 'app-auth-icon',
  templateUrl: './auth-icon.component.pug',
  styleUrls: ['./auth-icon.component.sass']
})
export class AuthIconComponent implements OnInit {

  @Input() data: AuthIconModel;
  @Output() isMouseOver = new EventEmitter();

	public change(): void {
   	this.isMouseOver.emit();
	}

  constructor() { }

  ngOnInit(): void {
  }

}
