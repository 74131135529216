import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileIconNamePipe } from "./file-icon-name.pipe";


@NgModule({
  declarations: [
    FileIconNamePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FileIconNamePipe
  ]
})
export class PipesModule {
}
