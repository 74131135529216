import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/lazy/+auth/auth.module').then(m => m.AuthModule)
  }, {
    path: '',
    loadChildren: () => import('./modules/lazy/+dashboard/dashboard.module').then(m => m.DashboardModule)
  }, {
    path: 'offline',
    loadChildren: () => import('./modules/lazy/+technical/health/health.module').then(m => m.HealthModule)
  }, {
    path: '**',
    loadChildren: () => import('./modules/lazy/+technical/not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
