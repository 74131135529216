import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SocialAuthBase} from '../SocialAuthBase';
import {AuthenticationService} from '../../../../../../../_services/authentication.service';
import {OAuthSystem} from '../../../../../../../_api/IdentityClient';
import {MatDialog} from '@angular/material/dialog';
import {FacebookLoginProvider} from '@abacritt/angularx-social-login';
import {SocialAuthService} from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-facebook-auth',
  templateUrl: './facebook-auth.component.pug',
  styleUrls: ['./facebook-auth.component.sass']
})
export class FacebookAuthComponent extends SocialAuthBase {

  constructor(socialAuthService: SocialAuthService,
              authenticationService: AuthenticationService,
              router: Router,
              route: ActivatedRoute,
              dialog: MatDialog) {
    super(socialAuthService, authenticationService, router, route, dialog);
  }

  protected providerId = FacebookLoginProvider.PROVIDER_ID;
  protected system = OAuthSystem.Facebook;

  protected subscribeToAuhState() {
  }
}
