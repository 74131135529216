import { MonoTypeOperatorFunction, Observable, ReplaySubject, Subscription } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { takeUntil as takeUntilDestroy } from "rxjs/operators";

/**
 * https://dev.azure.com/combinedratio/IMPACT/_wiki/wikis/IAM.wiki/334/DestroyToken-Pattern
 */
@Injectable()
export class DestroyToken extends ReplaySubject<void> implements OnDestroy {
  constructor() {
    super();
  }

  public notify(): void {
    this.next();
    this.complete();
  }

  ngOnDestroy(): void {
    this.notify();
  }

  takeUntil<T>(): MonoTypeOperatorFunction<T> {
    return takeUntilDestroy<T>(this);
  }

  observeTakeUntil<T>(observe: Observable<T>, subscribeAction: (a) => void): Subscription {
    return observe.pipe(this.takeUntil()).subscribe(a => subscribeAction(a));
  }
}
