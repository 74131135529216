import {environment} from '../../environments/environment';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig} from '@abacritt/angularx-social-login';
import {CustomGoogleProvider} from '@app/modules/lazy/+auth/components/login-registration/components/google-auth/custom-google-provider';

export class SocialAuthConfig {
  public static Configs = {
    autoLogin: false,
    providers: [
      {
        id: CustomGoogleProvider.PROVIDER_ID,
        provider: new CustomGoogleProvider(environment.socialConfig.googleClientId)
      },
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(environment.socialConfig.facebookClientId)
      }
    ],
    onError: (err) => {
      console.error(err);
    }
  } as SocialAuthServiceConfig;
}
