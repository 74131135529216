import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[inputMaxLength]'
})
export class InputMaxLengthDirective {

  @Input() inputMaxLength: number;

  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event']) onchange(event: any) {
    let value = this.el.value;
    if (value.length > this.inputMaxLength) {
      value = value.substr(0, value.length - 1);
      this.el.value = value;
      this.el.dispatchEvent(new Event('input', {bubbles: true}));
    }
  }
}
