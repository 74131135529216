import {Component, OnInit} from '@angular/core';
import { UpButtonService } from './_services/up-button.service';
import {AuthenticationService} from '@services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  title = 'SBN-WebApp';
  isAuthenticated = false;

  constructor(public upButtonService: UpButtonService,
              private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.authService.isAuthenticated$
      .subscribe(user => this.isAuthenticated = !!user);
  }
}
