import { Injectable } from '@angular/core';

class Expire {
  days?: number;
  hour?: number;
  minutes?: number;
  sec?: number;
}


@Injectable()
export class CookieService {

  constructor() {
  }

  public getCookie(name: string): string {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  public deleteCookie(name) {
    this.setCookie(name, '', -1);
  }

  public setCookieExpire(name: string, value: string, expireAfter: Expire, path: string = '') {
    const d: Date = new Date();
    let expireDays = expireAfter.days;
    const expireHours = expireAfter.hour;
    const expireMinutes = expireAfter.minutes;
    const expireSec = expireAfter.sec;
    if (!(expireDays > 0 && expireHours > 0 && expireMinutes > 0 && expireSec > 0)) {
      expireDays = 1;
    }
    if (expireDays > 0) {
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    }
    if (expireHours > 0) {
      d.setTime(d.getTime() + expireHours * 60 * 60 * 1000);
    }

    if (expireMinutes > 0) {
      d.setTime(d.getTime() + expireMinutes * 60 * 1000);
    }

    if (expireSec > 0) {
      d.setTime(d.getTime() + expireSec * 1000);
    }

    const expires: string = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + value + '; ' + expires + (path.length > 0 ? '; path=' + path : '');
  }

  public setCookie(name: string, value: string, expireDays: number, path: string = '') {
    this.setCookieExpire(name, value, { days: expireDays }, path);
  }
}
