import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../../../../../_services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorResponse, OAuthSystem, SwaggerException} from '../../../../../../_api/IdentityClient';
import {MatDialog} from '@angular/material/dialog';
import {first, flatMap} from 'rxjs/operators';
import {ConstConfig} from '../../../../../../_config/const.config';
import {LoginErrorComponent} from '../dialogs/login-error/login-error.component';
import {environment} from '../../../../../../../environments/environment';

@Component({template: ''})
// tslint:disable-next-line:component-class-suffix
export abstract class PopupSocialAuthBase implements OnInit {
  @Input() isDisabled: boolean;
  @Output() onLoading = new EventEmitter<boolean>();
  error: string;
  returnUrl: string;

  protected constructor(
    protected authenticationService: AuthenticationService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected dialog: MatDialog) {
  }

  protected abstract getPopupUri(): string;
  protected abstract getOAuthRequestSystem(): OAuthSystem;

  auth() {
    window.open(this.getPopupUri(), 'popup', 'width=600,height=600,location=middle');

    const receiveMessage = (event) => {
      if (event.origin !== environment.api.identity || event.data === 'user_cancelled_authorize') {
        return;
      }
      window.removeEventListener('message', receiveMessage, false);
      this.onLoading.next(true);
      this.authenticationService.socialLogin(event.data, this.getOAuthRequestSystem()).pipe(
        first()
      )
        .pipe(flatMap(() => this.authenticationService.getUserData()))
        .subscribe(async () => {
            await this.router.navigateByUrl(this.returnUrl);
            this.onLoading.next(false);
          },
          (error: ErrorResponse | SwaggerException) => {
            if (SwaggerException.isSwaggerException(error)) {
              error = JSON.parse(error.response);
            }
            if (error.message !== 'User cancelled login or did not fully authorize.') {
              this.showErrorDialog((<any>error.message).message || error.message);
            }
            this.onLoading.next(false);
          });
    };

    window.addEventListener('message', receiveMessage, false);
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || ConstConfig.DefaultRoute;
  }

  protected showErrorDialog(errorMsg: string): void {
    this.dialog.open(LoginErrorComponent, {
      width: '440px',
      data: {errorMsg}
    });
  }
}
