import { Component, OnInit } from '@angular/core';
import { UpButtonService } from 'src/app/_services/up-button.service';

@Component({
  selector: 'app-up-btn',
  templateUrl: './up-btn.component.pug',
  styleUrls: ['./up-btn.component.sass']
})

export class UpBtnComponent {

  constructor(private upButtonService: UpButtonService) {}

  scrollToTop(): void {
    window.scroll(0, 0);
    this.upButtonService.setVisible(false);
  }
}
