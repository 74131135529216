import { IWalletBalanceQueryResult } from '@api/SocialNetworkClient';

export class GetUserInviteCodeResult {
  inviteCode: string;

  constructor(data?: IWalletBalanceQueryResult) {
    if (data) {
      for (let property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      (<any>this).inviteCode = _data['inviteCode'];
    }
  }

  static fromJS(data: any): GetUserInviteCodeResult {
    data = typeof data === 'object' ? data : {};
    let result = new GetUserInviteCodeResult();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['inviteCode'] = this.inviteCode;
    return data;
  }
}
