import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../../../../_services/authentication.service';
import {Observable} from 'rxjs';
import {ErrorResponse, SwaggerException} from '@api/IdentityClient';
import {first, flatMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ConstConfig} from '../../../../../../_config/const.config';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.pug',
  styleUrls: ['./confirmation.component.sass']
})
export class ConfirmationComponent implements OnInit {

  error: string;
  submitted = false;
  returnUrl: string;
  @Input() token;
  @Output() onLoading = new EventEmitter<boolean>();
  useRecoveryCode = false;

  get changeModeBtnName(): string {
    return this.useRecoveryCode
      ? 'Use confirmation code?'
      : 'Use recovery code?';
  }

  get subtitle(): string {
    return this.useRecoveryCode
      ? 'If you lose access to your two-factor authentication credentials you can enter your recovery code below.'
      : 'Your login is protected with an authenticator app. Enter your authenticator code below. If you lose access to your two-factor authentication credentials you can use recovery code.';
  }

  get placeholder(): string {
    return this.useRecoveryCode
      ? 'Recovery code'
      : 'Confirmation code';
  }

  public confirmForm = this.formBuilder.group({
    code: ['', [Validators.required]]
  });

  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              protected router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || ConstConfig.DefaultRoute;
  }

  confirm() {
    if (this.confirmForm.invalid) {
      return;
    }

    const code = this.confirmForm.value.code;
    this.doSignIn(this.useRecoveryCode
      ? this.authenticationService.confirm2FaWithRecoveryCode(this.token, code)
      : this.authenticationService.confirm2Fa(this.token, code));
  }

  doSignIn(signInData: Observable<string>) {
    this.onLoading.next(true);
    this.error = '';
    signInData
      .pipe(
        first(),
      )
      .pipe(flatMap(() => this.authenticationService.getUserData()))
      .subscribe(
        async data => {
          if (data) {
            await this.router.navigateByUrl(this.returnUrl);
            this.onLoading.next(false);
          }
        },
        (error: ErrorResponse | SwaggerException) => {
          if (SwaggerException.isSwaggerException(error)) {
            this.error = JSON.parse(error.response);
          }
          if (error.message !== 'User cancelled login or did not fully authorize.') {
            this.error = error.message;
          }
          this.onLoading.next(false);
        });
  }

  toggleMode() {
    this.useRecoveryCode = !this.useRecoveryCode;
  }
}
