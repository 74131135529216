<div class="message" [ngClass]="{'message-sent':isAnswer}">
  <div class="message__avatar"></div>
  <div class="message__body">
    <div class="message__sender">{{messageSender}}</div>
    <div class="message__text" *ngIf="messageText">{{messageText}}</div>
    <div class="message__images" fxLayout="row wrap" fxLayoutGap="5px">
      <div fxLayoutAlign="center center" *ngFor="let imageView of message.Images" fxLayout="column" class="attached-image">
        <img class="image-view" [src]="host+imageView.path"
             [alt]="imageView.filename"
             (click)="downloadImage(imageView)"
             name="{{imageView.originalname}}"
             matTooltip="Download {{imageView.originalname}}">
        <div class="original-name">{{imageView.originalname}}</div>
      </div>
    </div>
    <div class="message__date">{{messageCreationDate| date: 'dd/MM/y h:mm a'}}</div>
  </div>
</div>
