import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ValidatorConfig} from '../../../../../../../_config/validator.config';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {
  IdentityClient,
  SendConfirmationCodeViewModel,
  SwaggerException,
  ConfirmEmailViewModel
} from '../../../../../../../_api/IdentityClient';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {BaseChangeEmailConfirm} from '../../../../../+dashboard/_components/change.email.confirm/BaseChangeEmailConfirm';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-telegram-email',
  templateUrl: './telegram-email.component.pug',
  styleUrls: ['./telegram-email.component.sass']
})
export class TelegramEmailComponent extends BaseChangeEmailConfirm<TelegramEmailComponent> implements OnInit {

  @ViewChild('questionTemplate', { static: true }) questionTemplate: TemplateRef<any>;
  @ViewChild('emailInputTemplate', { static: true }) emailInputTemplate: TemplateRef<any>;
  @ViewChild('confirmCodeTemplate', { static: true }) confirmCodeTemplate: TemplateRef<any>;
  currentTemplate: TemplateRef<any>;

  submitted = false;
  submittedCode = false;

  confirmEmailForm = this.formBuilder.group({
    email: ['', [ValidatorConfig.EmailValidator]]
  });

  confirmationCode = this.formBuilder.group({
    code: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]]
  });

  constructor(
    public dialogRef: MatDialogRef<TelegramEmailComponent>,
    private formBuilder: FormBuilder,
    private identityClient: IdentityClient,
    protected snackBar: MatSnackBar) {
    super(dialogRef, snackBar);
  }

  ngOnInit() {
    this.currentTemplate = this.questionTemplate;
  }

  sendCode() {
    this.submitted = true;
    if (this.confirmEmailForm.invalid) {
      return;
    }

    this.isLoading.next(true);
    this.error = '';
    this.identityClient.sendConfirmationCode(new SendConfirmationCodeViewModel(this.confirmEmailForm.value))
      .pipe(first())
      .subscribe(async () => {
          this.currentTemplate = this.confirmCodeTemplate;
          this.isLoading.next(false);
        },
        (error) => {
          if (SwaggerException.isSwaggerException(error)) {
            error = JSON.parse(error.response);
            if (error.message) {
              this.error = (<any>error.message).message || error.message;
            }
          } else {
            this.error = 'Such email was not registered in Ubikiri.';
          }
          this.isLoading.next(false);
        });
  }

  changeEmail(confirmationCode: string) {
    this.submittedCode = true;
    if (this.confirmationCode.invalid) {
      return;
    }
    this.isLoading.next(true);
    this.error = '';
    const request = new ConfirmEmailViewModel({
      confirmationCode: this.confirmationCode.value.code,
      email: this.confirmEmailForm.value.email
    });
    this.identityClient.confirmEmailWithCode(request)
      .pipe(first())
      .subscribe(async () => {
          this.dialogRef.close(this.confirmEmailForm.value.email);
          this.isLoading.next(false);
        },
        error => {
          if (SwaggerException.isSwaggerException(error)) {
            this.error = error.response;
          } else {
            this.error = 'Confirmation code is invalid or expired.';
          }
          this.isLoading.next(false);
        });
  }

  protected resendConfirmationCodeForChangeEmail(): Observable<void> {
    return this.identityClient.resendConfirmationCode2(this.confirmEmailForm.value.email);
  }

  protected confirmChangEmail(confirmationCode: string): Observable<void> {
    return undefined;
  }
}
