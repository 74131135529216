import {NgModule} from '@angular/core';
import {InputMaxLengthDirective} from './input-max-length.directive';

@NgModule({
  declarations: [
    InputMaxLengthDirective
  ],
  exports: [
    InputMaxLengthDirective
  ],
  imports: [

  ],
  entryComponents: []
})

export class InputMaxLengthModule {
}
