import {
  Component,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChildren,
  QueryList
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import * as MobileDetect from 'mobile-detect';
import { ChatApiService, IMessage, ISupportMsgImageView } from '@services/api/chat-api.service';
import { AuthenticationService } from '@services/authentication.service';
import { SupportService } from '@components/support/support.service';
import { NgxFileDropEntry } from "ngx-file-drop";
import { NgxFileDropHelper } from "@app/_helpers/ngxFileDropHelper";
import { BehaviorSubject, firstValueFrom, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse, HttpEvent, HttpEventType } from "@angular/common/http";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.pug',
  styleUrls: ['./chat.component.sass']
})
export class ChatComponent implements OnInit {

  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() dialogCreated: EventEmitter<any> = new EventEmitter();
  @ViewChild('messagesContainer', {static: true}) public scroll: ElementRef;
  @Input() dialogId: number;
  userEmail: string;
  messages: IMessage[] = [];


  commentForm = this.formBuilder.group({
    message: [''],
    files: [null]
  });

  mobileDetect = new MobileDetect(window.navigator.userAgent);
  imagesExtensions: string[] | string = 'image/*, .jpg, .jpeg, .png, .gif, .webp';
  loadingProgress: number;
  sending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  messagesSent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(private formBuilder: FormBuilder,
              private apiService: ChatApiService,
              private authService: AuthenticationService,
              private supportService: SupportService) {

  }

  ngOnInit(): void {
    this.authService.authenticatedUserData$
      .subscribe(user => this.userEmail = user?.email);
    this.initMessages();
    this.supportService.newMessage$.subscribe(data => {
      if (data) {
        this.initMessages();
      }
    });

    const filesForm = <FormControl>this.commentForm.get('files');
    const messageTextForm = <FormControl>this.commentForm.get('message');
    filesForm.valueChanges.subscribe((a) => {
      if (a?.length > 0) {
        messageTextForm.clearValidators();
        messageTextForm.updateValueAndValidity();
      } else {
        messageTextForm.setValidators([Validators.required]);
        messageTextForm.updateValueAndValidity();
      }
    })
    this.messagesSent$
      .subscribe(a => {
        if (a) {
          filesForm.setValue([]);
          messageTextForm.setValue(null);
        }
      })
  }

  initMessages() {
    if (!this.dialogId) {
      return;
    }

    this.apiService.getMessages(this.dialogId)
      .subscribe(data => {
        this.messages = data.data;
        this.scrollBottom();
      });
  }

  async sendMessage() {
    const message = this.commentForm.value.message;
    const files = this.commentForm.value.files;
    if (!message && !(files?.length > 0)) {
      return;
    }
    if ((files == null || files.length == 0) &&
      (message == null || message.length == 0)) {
      alert("Message text is required")
      return;
    }
    this.sending$.next(true);

    if (files?.length > 0)
      return await this.sendImage(files, message, this.dialogId)
    await this.sendText(message, this.dialogId);
  }

  closeClick(): void {
    this.onClose.emit();
  }

  scrollBottom() {
    setTimeout(() => {
      this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
    }, 0);
  }

  onFilesChanged(files: NgxFileDropEntry[]) {
    if (files?.length > 0)
      (<FormControl>this.commentForm.get('files')).setValue(NgxFileDropHelper.getFiles(...files));
    else
      (<FormControl>this.commentForm.get('files')).setValue([]);
  }

  async sendText(text: string, dialogId) {
    const response = await firstValueFrom(this.apiService.createMessage(text, dialogId));
    this.dialogId = response?.data?.DialogId;
    this.sending$.next(false);
    this.messagesSent$.next(true);
    this.initMessages();
  }

  async sendImage(images: File[], text: string, dialogId) {
    let formData = new FormData();
    let size = 0;
    let maxSize = 1024 * 1024;
    images.forEach((img) => {
      formData.append("images", img);
      size += img.size;
    });
    if (size > maxSize) {
      this.sending$.next(false);
      return alert(`Max attachments size: ${maxSize / 1024} KB`);
    }
    const params = {
      nnDialog: dialogId,
      projectCode: this.apiService.projectCode
    };

    const uploadImage = this.apiService.upload<any>('/messages/images', formData, {...params});
    await this.imageUploadingSub(uploadImage, async (currentDialogId: string) => {
      if (text) {
        await this.sendText(text, currentDialogId)
      } else {
        this.messagesSent$.next(true)
        this.sending$.next(false)
        this.initMessages();
      }
    });
  }

  private async imageUploadingSub(sub, completeCallback: (currentDialogId: string) => void) {
    sub.pipe(catchError((err: HttpErrorResponse, c) => {
      this.loadingProgress = 0;
      alert("Error uploading attachments")
      this.sending$.next(false);
      return of(null);
    })).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.loadingProgress = 0;
            break;
          case HttpEventType.Response:
            if (event.status === 200) {
              completeCallback(event.body.DialogId);
            }
            if (event.status < 200 || event.status >= 300) {

              alert("Error uploading attachments")
              this.loadingProgress = 0;
            }
            break;
          case HttpEventType.UploadProgress:
            this.loadingProgress = event['loaded'] / event['total'] * 100;
            if (this.loadingProgress == 100) {
              this.loadingProgress = 0;
            }
            break;
          default:
            this.loadingProgress = 0;
            break;
        }
        return event;
      }
    )
  }


  downloadImage(imageView: ISupportMsgImageView) {
    this.apiService.downloadImage(imageView);
  }

  openDialog: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  onOpenImagesDialog() {
    this.openDialog.next();
  }
}
