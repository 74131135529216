import {BehaviorSubject, merge, Observable, ReplaySubject, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, first, map} from 'rxjs/operators';
import {IdentityClient, ProfileMultipleQueryResult, Profile} from '../_api/IdentityClient';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class ProfileService {

  private socialNetworkUrl = environment.api.socialNetwork;

  private currentProfileSbj$ = new BehaviorSubject<Profile>(null);
  private profilesSbj$ = new ReplaySubject<Profile[]>(1);

  public profiles$ = this.profilesSbj$.asObservable();
  public currentProfile$ = this.currentProfileSbj$.asObservable();

  constructor(private identityClient: IdentityClient, private authenticationService: AuthenticationService) {
    this.authenticationService.isAuthenticated$.subscribe(user => {
      if (user) {
        this.refresh();
      } else {
        this.selectProfile(null);
      }
    });
  }

  public selectProfile(profile: Profile) {
    this.currentProfileSbj$.next(profile);
  }

  refresh(): void {
    this.getUserProfiles()
      .pipe(first())
      .subscribe(() => '', err => '');
  }

  getUserProfiles(): Observable<Profile[]> {
    return this.identityClient.getUserProfiles()
      .pipe(map((result: ProfileMultipleQueryResult) => {
        const currentProfile = this.currentProfileSbj$.value;
        if (!currentProfile) {
          this.currentProfileSbj$.next(result.data.filter(value => value.isDefault)[0]);
        } else {
          this.currentProfileSbj$.next(result.data.filter(value => value.id === currentProfile.id)[0]);
        }
        this.profilesSbj$.next(result.data);
        return result.data;
      }));
  }

  getProfileAvatarUrl(avatarUrl: string): string {
    return avatarUrl && (avatarUrl.includes('https://') || avatarUrl.includes('http://'))
      ? avatarUrl
      : this.socialNetworkUrl + '/Files?fileName=' + avatarUrl;
  }
}

