import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { ApiClient } from '@services/api/api-client.service';
import { InvitedWalletsChainedResult } from '@app/_models/invited-users/invited-wallets-chained.result';
import { catchError, map } from 'rxjs/operators';
import { InvitedUsersStoreActions } from '@app/_stores';
import { DestroyToken } from '@app/_models/utils/destroy-token';

@Injectable()
export class InvitedUsersEffects implements OnDestroy {
  rootRoute = '/InvitedUsers';
  requestRoute = (request: string = null) => `${ this.rootRoute }${ request ? '/' + request : '' }`;
  private destroy$: DestroyToken = new DestroyToken();

  constructor(private store$: Store<AppState>, private actions$: Actions, private apiClient: ApiClient) {
  }

  fetchInvitedUsers$ = createEffect(() => this.actions$.pipe(
    ofType<InvitedUsersStoreActions.FetchInvitedWallets>(InvitedUsersStoreActions.ActionTypes.FETCH_INVITED_WALLETS),
    switchMap((action) =>
      this.apiClient.get<InvitedWalletsChainedResult>(this.rootRoute)
        .pipe(
          map((a) => {
            return new InvitedUsersStoreActions.SetInvitedWallets(a);
          }),
          catchError(err => of(new InvitedUsersStoreActions.SetErrors(err)))
        )
    )
  ));

  ngOnDestroy(): void {
    this.destroy$.ngOnDestroy();
  }
}
