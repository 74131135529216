import { Injectable } from '@angular/core';
import { CookieService } from './cookie.service';

@Injectable()
export class InviteCodeCookiesService {
  public static key = 'COOKIES_INVITE_CODE';

  constructor(private cookies: CookieService) {
  }

  public Set(code: string) {
    this.cookies.setCookie(InviteCodeCookiesService.key, code, 20);
  }

  public Get() {
    return this.cookies.getCookie(InviteCodeCookiesService.key);
  }
}

