import {Component} from '@angular/core';
import {ValidatorConfig} from '../../../../../_config/validator.config';
import {AuthenticationService} from '../../../../../_services/authentication.service';
import {FormBuilder} from '@angular/forms';
import {EmailWithConfirmationActionRequest, ErrorResponse, IdentityClient} from '../../../../../_api/IdentityClient';
import {Router} from '@angular/router';
import {AuthConfig} from '../../../../../_config/auth.config';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.pug',
  styleUrls: ['./restore.component.sass']
})
export class RestoreComponent {
  error: string;
  loading = new Subject();
  submitted = false;

  restoreForm = this.formBuilder.group({
    email: ['', [ValidatorConfig.EmailValidator]],
  });

  constructor(private authenticationService: AuthenticationService,
              private formBuilder: FormBuilder,
              private identityClient: IdentityClient,
              private router: Router) {
  }

  onSubmit() {
    this.submitted = true;
    if (this.restoreForm.invalid) {
      return;
    }
    this.loading.next(true);
    this.error = '';
    const request = new EmailWithConfirmationActionRequest({
      email: this.restoreForm.value.email,
      confirmationAction: AuthConfig.RestoreConfirmationAction
    });

    this.identityClient.recoverPassword(request)
      .subscribe(async () => {
          localStorage.setItem('email', this.restoreForm.value.email);
          await this.router.navigate(['/auth', 'restore-complete']);
          this.loading.next(false);
        },
        (error: ErrorResponse) => {
          this.error = error.message;
          this.loading.next(false);
        });
  }
}
