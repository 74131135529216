import * as moment from 'moment';

import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AppHeadersService } from "../headers/app-headers.service";
import { Injectable } from "@angular/core";

@Injectable()
export class ApiClientBase {
    constructor(private http: HttpClient, private headers: AppHeadersService, private getUrl: () => string, private addDefaultParams?: (params: HttpParams) => HttpParams) {}

    public options<T>(path: string, paramsObject: Object = {}): Observable<T> {
        return this.http.options<T>(`${this.getUrl()}${path}`, this.createOptions(paramsObject));
    }

    public get<T>(path: string, paramsObject: Object = {}, isJson: boolean = false, isBlob: boolean = false): Observable<T> {
        return this.http.get<T>(`${this.getUrl()}${path}`, this.createOptions(paramsObject, isJson, isBlob));
    }

    public post<T>(path: string, body: Object, paramsObject: Object = {}): Observable<T> {
        return this.http.post<T>(`${this.getUrl()}${path}`, body, this.createOptions(paramsObject));
    }

    public put<T>(path: string, body: Object, paramsObject: Object = {}): Observable<T> {
        return this.http.put<T>(`${this.getUrl()}${path}`, body, this.createOptions(paramsObject));
    }

    public patch<T>(path: string, body: Object, paramsObject: Object = {}): Observable<T> {
        const request = [];
        for (const prop in body) {
            if (body.hasOwnProperty(prop)) {
                const upperCaseProperty = prop.charAt(0).toUpperCase() + prop.slice(1);
                request.push({ op: 'replace', path: `/${upperCaseProperty}`, value: body[prop] });
            }
        }
        return this.http.patch<T>(`${this.getUrl()}${path}`, request, this.createOptions(paramsObject));
    }

    public delete<T>(path: string, paramsObject: Object = {}): Observable<T> {
        return this.http.delete<T>(`${this.getUrl()}${path}`, this.createOptions(paramsObject));
    }

    public upload<T>(path: string, body: FormData, paramsObject: Object = {}): Observable<HttpEvent<T>> {
        const headers = this.createHeaders();
        let queryParams: HttpParams = new HttpParams();
        Object.keys(paramsObject).forEach(key => {
            if (paramsObject[key] != null) {
                queryParams = queryParams.append(key, paramsObject[key].toString());
            }
        });

        return this.http.request<T>('POST', `${this.getUrl()}${path}`, {
            params: queryParams,
            headers: headers,
            body: body,
            reportProgress: true,
            responseType: 'json',
            observe: 'events',
        });
    }

    private createHeaders(contentType?: string) {
        let header = new HttpHeaders(this.headers.values);
        if (contentType) {
            header = header.append('Content-Type', contentType);
        }
        return header;
    }

    private createOptions(paramsObject: Object, isJson: boolean = false, isBlob = false): Object {
        const headers = this.createHeaders('application/json');
        let queryParams: HttpParams = new HttpParams();
        if (isJson) {
            paramsObject = { obj: encodeURI(JSON.stringify(paramsObject)) };
        }

        Object.keys(paramsObject).forEach(key => {
            if (paramsObject[key] != null) {
                if (paramsObject[key].constructor === Array) {
                    paramsObject[key].forEach(element => {
                        queryParams = queryParams.append(key, element ? element.toString() : null);
                    });
                } else if (moment.isMoment(paramsObject[key])) {
                    queryParams = queryParams.append(key, paramsObject[key].toISOString());
                } else {
                    queryParams = queryParams.append(key, paramsObject[key].toString());
                }
            }
        });

        return {
            params: this.addDefaultParams ? this.addDefaultParams(queryParams) : queryParams,
            headers: headers,
            observe: isBlob ? 'response' : 'body',
            responseType: isBlob ? 'blob' : 'json'
        };
    }
}
