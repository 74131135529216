import {AuthenticationService} from '../../../../../../_services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, flatMap, first, filter} from 'rxjs/operators';
import {
  ErrorResponse,
  IErrorResponse,
  OAuthSystem,
  SwaggerException
} from '../../../../../../_api/IdentityClient';
import {throwError} from 'rxjs';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConstConfig} from '../../../../../../_config/const.config';
import {LoginErrorComponent} from '../dialogs/login-error/login-error.component';
import {MatDialog} from '@angular/material/dialog';
import {SocialUser} from '@abacritt/angularx-social-login';
import {SocialAuthService} from '@abacritt/angularx-social-login';
import {from} from 'rxjs';

@Component({template: ''})
// tslint:disable-next-line:component-class-suffix
export abstract class SocialAuthBase implements OnInit {
  @Input() isDisabled: boolean;
  @Output() onLoading = new EventEmitter<boolean>();
  returnUrl: string;

  protected abstract providerId: string;
  protected abstract system: OAuthSystem;


  protected constructor(
    protected socialAuthService: SocialAuthService,
    protected authenticationService: AuthenticationService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected dialog: MatDialog) {
  }

  protected abstract subscribeToAuhState();

  auth() {
    this.authenticationService.isAuthenticated$
      .pipe(first())
      .pipe(filter((isAuth: string) => !isAuth))
      .pipe(flatMap(() => from(this.socialAuthService.signIn(this.providerId))))
      .pipe(
        flatMap((socialUser: SocialUser) => {
          this.onLoading.next(true);
          return this.authenticationService.socialLogin(socialUser.idToken || socialUser.authToken, this.system);
        }),
        catchError(err => throwError(new ErrorResponse(<IErrorResponse> {message: err})))
      )
      .pipe(flatMap(() => this.authenticationService.getUserData()))
      .subscribe(
        async () => {
          await this.router.navigateByUrl(this.returnUrl);
          this.onLoading.next(false);
        },
        (error: ErrorResponse | SwaggerException) => {
          if (SwaggerException.isSwaggerException(error)) {
            error = JSON.parse(error.response);
          }
          if (error.message !== 'User cancelled login or did not fully authorize.') {
            this.showErrorDialog((<any>error.message).message || error.message);
          }
          this.onLoading.next(false);
        });
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || ConstConfig.DefaultRoute;
    this.subscribeToAuhState();
  }

  showErrorDialog(errorMsg: string): void {
    this.dialog.open(LoginErrorComponent, {
      width: '440px',
      data: {errorMsg}
    });
  }
}
