import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDropComponent } from "./file-drop.component";
import { NgxFileDropModule } from "ngx-file-drop";
import { MatIconModule } from "@angular/material/icon";
import { FlexModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatBadgeModule } from "@angular/material/badge";
import { MatMenuModule } from "@angular/material/menu";
import { PipesModule } from "../../../_pipes/pipes.module";

@NgModule({
  declarations: [FileDropComponent],
  exports: [
    FileDropComponent
  ],
    imports: [
        CommonModule,
        NgxFileDropModule,
        MatIconModule,
        FlexModule,
        MatButtonModule,
        MatFormFieldModule,
        PipesModule,
        MatBadgeModule,
        MatMenuModule,
        PipesModule
    ]
})
export class FileDropModule { }
