import { Component, EventEmitter, Input, Output } from '@angular/core';
import {IDialog} from '@services/api/chat-api.service';

@Component({
  selector: 'app-dialogs',
  templateUrl: './dialogs.component.pug',
  styleUrls: ['./dialogs.component.sass']
})
export class DialogsComponent {
  @Input() dialogs: IDialog[] = [];
  @Input() activeDialog: number | null = null;
  @Output() onDialogClick: EventEmitter<any> = new EventEmitter();

  dialogClick(id: number): void {
    this.onDialogClick.emit(id);
    const dialog = this.dialogs.find(x => x.Id === id);
    dialog.Unread = 0;
  }

  createDialog(): void {
    this.onDialogClick.emit(null);
  }

  ellipseText(text: string): string {
    return text.length < 45 ? text : text.slice(0, 40).concat('...');
  }
}
