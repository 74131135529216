import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.pug',
  styleUrls: ['./login-error.component.sass']
})
export class LoginErrorComponent {

  errorMsgSubj = new BehaviorSubject<string>(null);

  constructor(public dialogRef: MatDialogRef<LoginErrorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.errorMsgSubj.next(data.errorMsg);
  }

  closeClick(): void {
    this.dialogRef.close();
  }
}
