import { Pipe, PipeTransform } from '@angular/core';
import { NgxFileDropEntry } from "ngx-file-drop";

@Pipe({
  name: 'fileIconName'
})
export class FileIconNamePipe implements PipeTransform {

  transform(file: NgxFileDropEntry, ...args: unknown[]): unknown {
    let fileName = file.fileEntry.name.toLowerCase();
    if (fileName) {
      let fileExtensionStart = fileName?.lastIndexOf('.') ?? null;
      let fileExtension = fileName?.substring(fileExtensionStart + 1) ?? null;

      switch (fileExtension) {
        case 'jpg':
        case 'jpeg':
        case 'ico':
        case'png':
        case'svg':
          return 'image';
        default:
          return 'attach_file';
      }
    }
    return 'attach_file';
  }
}
