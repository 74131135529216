import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RestoreComponent} from './components/restore/restore.component';
import {RegistrationCompleteComponent} from './components/registration-complete/registration-complete.component';
import {RestoreCompleteComponent} from './components/restore-complete/restore-complete.component';
import {RestoreConfirmComponent} from './components/restore-confirm/restore-confirm.component';
import {RestoreConfirmCompleteComponent} from './components/restore-confirm-complete/restore-confirm-complete.component';
import {RegistrationSuccessComponent} from './components/registration-success/registration-success.component';
import {LoginRegistrationComponent} from './components/login-registration/login-registration.component';
import { TelegramAuthComponent } from './components/login-registration/components/telegram-auth/telegram-auth.component';
import {TelegramWidgetComponent} from './components/login-registration/components/telegram-widget/telegram-widget.component';

const routes: Routes = [
  {
    path: 'login-registration',
    component: LoginRegistrationComponent
  }, {
    path: 'registration-complete',
    component: RegistrationCompleteComponent
  }, {
    path: 'restore',
    component: RestoreComponent
  }, {
    path: 'restore-complete',
    component: RestoreCompleteComponent
  }, {
    path: 'restore-confirm',
    component: RestoreConfirmComponent
  }, {
    path: 'restore-confirm-complete',
    component: RestoreConfirmCompleteComponent
  }, {
    path: 'registration-success',
    component: RegistrationSuccessComponent
  }, {
    path: 'telegram-widget',
    component: TelegramWidgetComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
