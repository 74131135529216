import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './users.state';
import { StoreTypes } from '../store-types';
import { Enums } from '@app/_models/app/enums';


const usersState = createFeatureSelector<State>(StoreTypes.users);

export const getRequestsInProgress = createSelector(usersState, a => a.requestInProgress);

export const getUsers = createSelector(usersState, a => a.users ?? []);
export const getIsLoading = createSelector(getRequestsInProgress, a => a.users);

export const getShowInvitedUsersTable = createSelector(usersState, a => a.showInvitedUsersTable);

export const getCountInvitedUsers = createSelector(usersState, a => a.countInvitedUsers);
export const getIsLoadingCountInvitedUsers = createSelector(getRequestsInProgress, a => a.countInvitedUsers);

export const getUserIsSuperReferer = createSelector(usersState, a => a.userProperties?.find(a => a.type.id === Enums.UserPropertyTypes.SuperReferer) !== null ?? false);
export const getUserTelegramId = createSelector(usersState, a => a.userProperties?.find(a => a.type.id === Enums.UserPropertyTypes.TelegramId));
export const getUserTwitterId  = createSelector(usersState, a => a.userProperties?.find(a => a.type.id === Enums.UserPropertyTypes.TwitterId));

export const getErrors = createSelector(usersState, a => a.errors);

