import {Validators} from '@angular/forms';
import {CustomValidators} from '../_helpers/custom.validator';

export class ValidatorConfig {
  public static EmailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  public static PasswordMinLength = 6;
  public static PasswordKeyMinLength = 6;

  public static EmailValidator = Validators.compose([
    Validators.required,
    Validators.pattern(ValidatorConfig.EmailRegExp)]);

  public static NotRequiredEmailValidator = Validators.compose([Validators.pattern(ValidatorConfig.EmailRegExp)]);

  public static PasswordValidator = Validators.compose([
    Validators.required,
    Validators.minLength(ValidatorConfig.PasswordMinLength),
    CustomValidators.namedPatternValidator(/\d/, { hasNumber: true }),
    CustomValidators.namedPatternValidator(/[!@#$%^&*()_+\-=?]/, { hasSpecialCharacters: true }),
    CustomValidators.namedPatternValidator(/[A-Z]/, { hasCapitalCase: true }),
    CustomValidators.namedPatternValidator(/[a-z]/, { hasSmallCase: true }),
  ]);

  public static PasswordKeyValidator = Validators.compose([
    Validators.required,
    Validators.minLength(ValidatorConfig.PasswordKeyMinLength),
    CustomValidators.namedPatternValidator(/[A-Z]/, { hasCapitalCase: true }),
    CustomValidators.namedPatternValidator(/[a-z]/, { hasSmallCase: true }),
    CustomValidators.namedPatternValidator(/\d/, { hasNumber: true }),
    CustomValidators.namedPatternValidator(/[!@#$%^&*()_+\-=?]/, { hasSpecialCharacters: true })
  ]);

}
