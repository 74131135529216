export class AuthIconModel {

    name: string;
    data: any[];
    isShow: boolean;
    hideGroups: boolean;
    noGroups: boolean;
    showTitles: boolean;
    defaultIcon: string;

    constructor(name: string, data: any[], isShow: boolean, hideGroups: boolean, noGroups: boolean, showTitles: boolean, defaultIcon: string) {
        this.name = name;
        this.data = data;
        this.isShow = isShow;
        this.hideGroups = hideGroups;
        this.noGroups = noGroups;
        this.showTitles = showTitles;
        this.defaultIcon = defaultIcon;
    }
}