import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatApiService, IMessage, ISupportMsgImageView } from "@services/api/chat-api.service";

@Component({
  selector: 'app-support-msg-view',
  templateUrl: './support-msg-view.component.html',
  styleUrls: ['./support-msg-view.component.sass']
})
export class SupportMsgViewComponent implements OnInit {
  @Input() isAnswer: boolean;
  @Input() message: IMessage;
  @Output() download: EventEmitter<ISupportMsgImageView> = new EventEmitter<ISupportMsgImageView>();
  messageSender: string;
  messageText: string;
  messageCreationDate: Date;
  public readonly host: string;

  constructor(api: ChatApiService) {
    this.host = api.host;
  }

  ngOnInit(): void {
    this.messageSender = this.message?.Sender;
    this.messageText = this.message?.Text;
    this.messageCreationDate = this.message?.CreationDate;
  }

  downloadImage(imageView: ISupportMsgImageView) {
    this.download.emit(imageView);
  }
}
