import {Component, OnInit} from '@angular/core';
import {ValidatorConfig} from '../../../../../_config/validator.config';
import {FormBuilder, Validators} from '@angular/forms';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {ConfirmPasswordRecoveryRequest, ErrorResponse, IdentityClient} from '../../../../../_api/IdentityClient';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-restore-confirm',
  templateUrl: './restore-confirm.component.pug',
  styleUrls: ['./restore-confirm.component.sass']
})

export class RestoreConfirmComponent implements OnInit  {
  validatorConfig = ValidatorConfig;
  error: string;
  loading = new Subject();
  submitted = false;
  showPwd = false;
  showPwdRepeat = false;

  restoreForm = this.formBuilder.group({
    email: ['', [ValidatorConfig.EmailValidator]],
    token: ['', [Validators.required]],
    newPassword: ['', [ValidatorConfig.PasswordValidator]],
    confirmNewPassword: ['', [Validators.required, RxwebValidators.compare({fieldName: 'newPassword'})]]
  });

  constructor(private formBuilder: FormBuilder,
              private identityClient: IdentityClient,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.restoreForm.controls['token'].setValue(params.token);
      if (params.email) {
        this.restoreForm.controls['email'].setValue(atob(params.email));
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.restoreForm.invalid) {
      return;
    }
    this.loading.next(true);
    this.error = '';
    const request = new ConfirmPasswordRecoveryRequest(this.restoreForm.value);

    this.identityClient.recoverConfirm(request)
      .subscribe(async () => {
          await this.router.navigate(['/auth', 'restore-confirm-complete']);
          this.loading.next(false);
        },
        (error: ErrorResponse) => {
          this.error = error.message;
          this.loading.next(false);
        });
  }
}
