import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(async err => {
      if (err.status === 401) {
        let renewResult = await this.authenticationService.checkAccessToken();
        if (renewResult != null)
          await next.handle(request);
        else
          await this.authenticationService.logout();
      }

      return await firstValueFrom(throwError(err));
    }));
  }
}
