import { Injectable } from '@angular/core';
import { ApiClientBase } from './api-client-base.service';
import { HttpClient } from '@angular/common/http';
import { AppHeadersService } from '../headers/app-headers.service';
import { EnvService } from '../env/env.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatApiService extends ApiClientBase {

  public readonly projectCode = 'ubikiri';
  public readonly host: string;

  constructor(http: HttpClient, headers: AppHeadersService, hosts: EnvService) {
    super(http, headers, () => hosts.chatUrl);
    this.host = hosts.chatUrl;
  }

  createMessage(text: string, dialogId: number): Observable<IMessageResponse> {
    const request = {
      nnDialog: dialogId ? dialogId : 0,
      text: text,
      projectCode: this.projectCode
    } as ICreateMessageRequest;

    return this.post<IMessageResponse>('/messages/create', request);
  }

  getMessages(dialogId: number, lastIndex?: number): Observable<IDialogMessagesResponse> {
    let past = `/messages/dialog/${dialogId}?projectCode=${this.projectCode}`;
    if (lastIndex) {
      past = `${past}&lastIndex=${lastIndex}`;
    }
    return this.get<IDialogMessagesResponse>(past);
  }

  subscribeToMessage(dialogId: number): Observable<IMessageResponse> {
    return this.get(`/messages/subscribe/${dialogId}`);
  }

  getDialogs(): Observable<IDialogsResponse> {
    return this.get(`/dialogs/user?projectCode=${this.projectCode}`);
  }

  getImagePath(image: ISupportMsgImageView) {
    return this.host + image.path;
  }

  downloadImage(imageView: ISupportMsgImageView) {
    const imgUrl = this.getImagePath(imageView);
    const linkElm = document.createElement('a');
    linkElm.setAttribute('href', imgUrl);
    linkElm.setAttribute('download', imageView.originalname);
    linkElm.setAttribute('file', imageView.originalname);
    linkElm.setAttribute('name', imageView.originalname);
    linkElm.click();
  }
}

export interface ICreateMessageRequest {
  nnDialog: number;
  text: string;
  projectCode: string;
}

export interface IMessageResponse {
  status: string;
  data: IMessage;
}

export interface IDialogMessagesResponse {
  status: string;
  data: IMessage[];
}

export interface IMessageResponse {
  status: string;
  data: IMessage;
}

export interface IDialogsResponse {
  status: string;
  data: IDialog[];
  unread: number;
  project: IProject;
}

export interface ISupportMsgImageView {
  destination: string;
  encoding: string
  fieldname: string;
  filename: string;
  mimetype: string;
  originalname: string;
  path: string;
  size: number;
}

export interface IMessage {
  Id: number;
  Sender: string;
  Text: string;
  IsRead: boolean;
  DialogId: 1;
  CreationDate: Date;
  Images: ISupportMsgImageView[];
}


export interface IDialog {
  Id: number;
  Client: string;
  Type: string;
  Status: string;
  Unread: number;
  Message: IMessage;
}

export interface IProject {
  id: number;
  name: string;
}
