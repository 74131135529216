import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../../../environments/environment';
import { AuthIconModel } from '../../../../../../../_models/External/AuthIconModel'

@Component({
  selector: 'app-auth-icons',
  templateUrl: './auth-icons.component.pug',
  styleUrls: ['./auth-icons.component.sass']
})
export class AuthIconsComponent implements OnInit {

  appData: AuthIconModel;
  webData: AuthIconModel;
  docData: AuthIconModel;

  public changeApp(): void {
    if (environment.startPageIcons.hideGroups) {
      this.appData.isShow = true;
      this.webData.isShow = this.docData.isShow = false;
    }
  }
  public changeWeb(): void {
    if (environment.startPageIcons.hideGroups) {
      this.webData.isShow = true;
      this.appData.isShow = this.docData.isShow = false;
    }
  }
  public changeDoc(): void {
    if (environment.startPageIcons.hideGroups) {
      this.docData.isShow = true;
      this.appData.isShow = this.webData.isShow = false;
    }
  }

  constructor() {
    this.appData = new AuthIconModel(
      "Applications",
      environment.startPageIcons.applicationsIcons,
      true,
      environment.startPageIcons.hideGroups,
      environment.startPageIcons.noGroups,
      environment.startPageIcons.showTitles,
      environment.startPageIcons.defaultIconSrc
    );
    this.webData = new AuthIconModel(
      "Web resources",
      environment.startPageIcons.webResourcesIcons,
      true,
      environment.startPageIcons.hideGroups,
      environment.startPageIcons.noGroups,
      environment.startPageIcons.showTitles,
      environment.startPageIcons.defaultIconSrc
    );
    this.docData = new AuthIconModel(
      "Documentation",
      environment.startPageIcons.documentationIcons,
      true,
      environment.startPageIcons.hideGroups,
      environment.startPageIcons.noGroups,
      environment.startPageIcons.showTitles,
      environment.startPageIcons.defaultIconSrc
    );
    if (environment.startPageIcons.hideGroups) {
      this.webData.isShow = false;
      this.docData.isShow = false;
    }
  }

  ngOnInit(): void {
  }

}
