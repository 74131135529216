import { Action } from '@ngrx/store';
import { InvitedWalletsChainedResult } from '@app/_models/invited-users/invited-wallets-chained.result';

export enum ActionTypes {
  FETCH_INVITED_WALLETS = '[InvitedUsers] FETCH_INVITED_WALLETS',
  SET_INVITED_WALLETS = '[InvitedUsers] SET_INVITED_WALLETS',
  SET_ERRORS = '[InvitedUsers] SET_ERRORS',
}

export class FetchInvitedWallets implements Action {
  readonly type = ActionTypes.FETCH_INVITED_WALLETS;

  constructor() {
  }
}

export class SetInvitedWallets implements Action {
  readonly type = ActionTypes.SET_INVITED_WALLETS;

  constructor(public payload: InvitedWalletsChainedResult) {
  }
}


export class SetErrors implements Action {
  readonly type = ActionTypes.SET_ERRORS;

  constructor(public payload: string[] | string) {
  }
}


export type Actions = FetchInvitedWallets
  | SetInvitedWallets
  | SetErrors
  ;
