import { NgModule } from '@angular/core';
import { StoreTypes } from '../store-types';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { usersReducer } from './users.reducer';
import { UsersEffects } from '@app/_stores/users/users.effects';

@NgModule({
  imports:[
    StoreModule.forFeature(StoreTypes.users, usersReducer),
    EffectsModule.forFeature([UsersEffects])
  ]
})
export class UsersStoreModule {}
