import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../../../../_services/authentication.service';
import { ConfirmEmailRequest, ErrorResponse, IdentityClient, SignInResponse, SwaggerException, UserResponse } from '@api/IdentityClient';
import { ActivatedRoute, Router } from '@angular/router';
import {EMPTY, Observable} from 'rxjs';
import { first, flatMap } from 'rxjs/operators';
import { ConstConfig } from '../../../../../../_config/const.config';
import { SocialNetworkClient } from '@api/SocialNetworkClient';
import { MatDialog } from '@angular/material/dialog';
import { TwoFaReminderComponent } from '../dialogs/two-fa-reminder/two-fa-reminder.component';
import { environment } from '../../../../../../../environments/environment';
import { CookieService } from '../../../../../../_services/cookies/cookie.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.pug',
  styleUrls: ['./login.component.sass']
})

export class LoginComponent implements OnInit {
  @Output() onLoading = new EventEmitter<boolean>();
  @Output() toggle: EventEmitter<null> = new EventEmitter();
  invalidCount = 0;
  maxIncorrectLoginCount: number = environment.incorrectLoginConfig.count;
  disableLoginSec = environment.incorrectLoginConfig.timoutSec;
  defaultDisableLoginSec = environment.incorrectLoginConfig.timoutSec;

  disableLogin = false;

  submitted = false;
  showPwd = false;
  error: string;
  returnUrl: string;
  emailConfirmed = false;
  isConfirmation = false;
  twoFaToken: string;


  public loginForm = this.formBuilder.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });

  constructor(protected authenticationService: AuthenticationService,
              protected router: Router,
              private identityClient: IdentityClient,
              private webApi: SocialNetworkClient,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private cookieService: CookieService) {

  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || ConstConfig.DefaultRoute;

    this.route.queryParams.subscribe(data => {
      const email = data.email;
      const token = data.token;

      if (email && token) {
        this.onLoading.next(true);
        this.error = '';

        const request = new ConfirmEmailRequest({
          email: email,
          confirmationToken: token
        });
        this.identityClient.confirmEmail(request)
          .subscribe(() => {
              this.onEmailConfirmed(email);
            },
            (error: ErrorResponse) => {
              this.error = error.message;
              this.onLoading.next(false);
            });
      }
    });

    let blockDate = this.cookieService.getCookie('blockLogin');
    if (blockDate) {
      this.startDisableLoginInterval(new Date(Date.parse(blockDate)));
    }
  }

  private addInviteCode(cookies, email) {
    this.webApi.registerInviteCode(cookies, email).subscribe(() => {
        this.onEmailConfirmed(email);
      },
      (error: ErrorResponse) => {
        this.error = error.message;
        this.onLoading.next(false);
      }
    );
  }

  private onEmailConfirmed(email) {
    this.emailConfirmed = true;
    this.loginForm.controls['email'].setValue(atob(email));
    this.onLoading.next(false);
  }

  public signIn() {
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = true;
    let login = this.authenticationService.login(this.loginForm.value.email, this.loginForm.value.password);
    this.doSignIn(login);
  }

  doSignIn(signInData: Observable<SignInResponse>) {
    this.onLoading.next(true);
    this.error = '';
    signInData
      .pipe(
        first(),
      )
      .pipe(flatMap(data => this.finalizeLogin(data)))
      .subscribe(
        async data => {
          if (data) {
            await this.router.navigateByUrl(this.returnUrl);
            this.onLoading.next(false);
          }
        },
        (error: ErrorResponse | SwaggerException) => {
          this.onLoading.next(false);
          this.onLoginError();
          if (SwaggerException.isSwaggerException(error)) {
            this.error = JSON.parse(error.response);
          }
          if (error.message !== 'User cancelled login or did not fully authorize.') {
            this.error = error.message;
          }
        });
  }

  private onLoginError() {
    if (!(this.maxIncorrectLoginCount > ++this.invalidCount)) {
      const blockSeconds = environment.incorrectLoginConfig.timoutSec;
      let nowDate = new Date();
      this.cookieService.setCookieExpire('blockLogin', nowDate.toUTCString(), { sec: blockSeconds });
      this.startDisableLoginInterval(nowDate);
    }
  }

  private startDisableLoginInterval(timeStart: Date) {
    let now = new Date();
    let blockSecondsOffset = this.defaultDisableLoginSec - ((now.getTime() - timeStart.getTime()) / 1000);
    if (blockSecondsOffset < 0) {
      this.cookieService.deleteCookie('blockLogin');
      this.disableLogin = false;
      return;
    }
    this.disableLoginSec = blockSecondsOffset;
    let end = new Date(timeStart.getTime());
    end.setTime(timeStart.getTime() + 1000 * this.defaultDisableLoginSec);
    this.disableLogin = true;
    let timerId = setInterval(() => {
      let interval = (end.getTime() - (new Date().getTime())) / 1000;
      if (interval > 0) {
        this.disableLoginSec = interval;

        this.disableLogin = true;
      } else {
        this.disableLoginSec = this.defaultDisableLoginSec;
        this.invalidCount = 0;
        this.disableLogin = false;
        this.cookieService.deleteCookie('blockLogin');
        clearInterval(timerId);
      }
    }, 1000);
  }

  finalizeLogin(signInResponse: SignInResponse): Observable<UserResponse> {
    if (signInResponse.access_token && signInResponse.latestTwoFaRemindDate) {
      return this.authenticationService.getUserData();
    }

    if (signInResponse.access_token && !signInResponse.latestTwoFaRemindDate) {
      return this.dialog.open(TwoFaReminderComponent, {
        width: '440px'
      }).afterClosed()
        .pipe(flatMap(isProtect => {
          if (!isProtect) {
            return this.identityClient
              .disableTwoFaReminder()
              .pipe(flatMap(() => this.authenticationService.getUserData()));
          }
          this.router.navigate(['/settings-account'], { queryParams: { open2FaSettings: true } });
          return EMPTY;
        }));
    }

    if (signInResponse.twoFaRequired) {
      this.isConfirmation = true;
      this.onLoading.next(false);
      this.twoFaToken = signInResponse.twoFaToken;
      return EMPTY;
    }

    return EMPTY;
  }

  toRegistration() {
    this.toggle.emit();
  }


  resentEmailForConfirmation(): void {
    localStorage.setItem('email', this.loginForm.value.email);
    this.router.navigate(['/auth', 'registration-complete']);
  }
}
