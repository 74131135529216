import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../../../../environments/environment';

@Component({
  selector: 'app-telegram-widget',
  templateUrl: './telegram-widget.component.pug',
  styleUrls: ['./telegram-widget.component.sass']
})
export class TelegramWidgetComponent implements OnInit {

  botName = environment.socialConfig.telegramBotName;

  constructor(public dialogRef: MatDialogRef<TelegramWidgetComponent>) { }

  ngOnInit() {
  }
}
