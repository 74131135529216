import { Action } from '@ngrx/store';
import { InvitedWalletsChainedResult } from '@app/_models/invited-users/invited-wallets-chained.result';
import { UserBaseView } from '@app/_models/users/user-base-view';
import { UserPropertyView } from '@app/_models/users/user-property-view';

export enum ActionTypes {
  SET_ERRORS = '[Users] SET_ERRORS',
  FETCH_USERS_BY_IDS = '[Users] FETCH_USERS_BY_IDS',
  FETCH_CHAIN_INVITED_WALLETS = '[Users] FETCH_CHAIN_INVITED_WALLETS',
  FETCH_COUNT_INVITED_USERS = '[Users] FETCH_COUNT_INVITED_USERS',
  SET_COUNT_INVITED_USERS = '[Users] SET_COUNT_INVITED_USERS',
  SHOW_INVITED_USERS_TABLE = '[Users] SHOW_INVITED_USERS_TABLE',
  SET_USERS = '[Users] SET_USERS',
  FETCH_USER_PROPERTIES = '[Users] FETCH_USER_PROPERTIES',
  SET_USER_PROPERTIES = '[Users] SET_USER_PROPERTIES',
  UPDATE_USER_TELEGRAM = '[Users] UPDATE_USER_TELEGRAM',
  UPDATE_USER_TWITTER = '[Users] UPDATE_USER_TWITTER',
}

export class FetchUsersByIds implements Action {
  readonly type = ActionTypes.FETCH_USERS_BY_IDS;

  constructor(public ids: string[]) {
  }
}

export class FetchCountInvitedUsers implements Action {
  readonly type = ActionTypes.FETCH_COUNT_INVITED_USERS;

  constructor() {
  }
}

export class SetCountInvitedUsers implements Action {
  readonly type = ActionTypes.SET_COUNT_INVITED_USERS;

  constructor(public payload: number) {
  }
}

export class ShowInvitedUsersTable implements Action {
  readonly type = ActionTypes.SHOW_INVITED_USERS_TABLE;

  constructor() {
  }
}


export class FetchChainInvitedWallets implements Action {
  readonly type = ActionTypes.FETCH_CHAIN_INVITED_WALLETS;

  constructor(public payload: InvitedWalletsChainedResult) {
  }
}

export class SetUsers implements Action {
  readonly type = ActionTypes.SET_USERS;

  constructor(public payload: UserBaseView[]) {
  }
}

export class FetchUserProperties implements Action {
  readonly type = ActionTypes.FETCH_USER_PROPERTIES;

  constructor() {
  }
}

export class SetUserProperties implements Action {
  readonly type = ActionTypes.SET_USER_PROPERTIES;

  constructor(public payload: UserPropertyView[]) {
  }
}

export class UpdateUserTelegram implements Action {
  readonly type = ActionTypes.UPDATE_USER_TELEGRAM;

  constructor(public value: string) {
  }
}

export class UpdateUserTwitter implements Action {
  readonly type = ActionTypes.UPDATE_USER_TWITTER;

  constructor(public value: string) {
  }
}


export class SetErrors implements Action {
  readonly type = ActionTypes.SET_ERRORS;

  constructor(public payload: string[] | string) {
  }
}


export type Actions = FetchUsersByIds
  | FetchChainInvitedWallets
  | SetUsers
  | FetchCountInvitedUsers
  | SetCountInvitedUsers
  | ShowInvitedUsersTable
  | FetchUserProperties
  | SetUserProperties
  | UpdateUserTwitter
  | UpdateUserTelegram
  | SetErrors
  ;
