import { UserBaseView } from '@app/_models/users/user-base-view';
import { UserPropertyView } from '@app/_models/users/user-property-view';

export interface RequestInProgress {
  users: boolean;
  userProperties: boolean;
  countInvitedUsers: boolean;
}


export function initRequestInProgress(): RequestInProgress {
  return {
    countInvitedUsers: false,
    userProperties: false,
    users: false
  };
}

export const requestInProgressNames = {
  users: 'users:',
  userProperties: 'userProperties',
  countInvitedUsers: 'countInvitedUsers'
};

export interface State {
  users: UserBaseView[];
  userProperties: UserPropertyView[];
  showInvitedUsersTable: boolean;
  countInvitedUsers: number;
  errors: string[] | string;
  requestInProgress: RequestInProgress;
}


export function setRequestInProgress(state: State, propertyName: string, value: boolean = true): RequestInProgress {
  let newState = {
    ...state.requestInProgress,
  };
  newState[propertyName] = value;
  return newState;
}

export function resetRequestInProgress(state: State, propertyName: string): RequestInProgress {
  return setRequestInProgress(state, propertyName, false);
}
