export const environment = {
  production: true,
  headers: {
    'Accept': 'application/json'
  },
  api: {
    identity: '/identity',
    socialNetwork: '/api',
    _4tokens: 'https://fortoken-api.dev20021.ubikiri.com',
    cdr: 'https://cdr-api.dev20021.ubikiri.com',
    cdrKey: 'DSJGHFJK6457JHFHV54efhjFJYY5',
    beeqb: 'https://qq.ubikiri.com',
    chat: 'https://chat-api.dev20021.ubix.network/api'
  },
  socialConfig: {
    googleClientId: '915844553514-403tllqhd6a7d3g5oh1888h48ao8k6jq.apps.googleusercontent.com',
    facebookClientId: '263594838508604',
    telegramBotName: 'silent_notary_test_bot'
  },
  incorrectLoginConfig: {
    count: 5,
    timoutSec: 60,
  },
  thirdPartyUris: {
    explorerUri: 'https://test-explorer.ubikiri.com/',
    ethExplorereUri: 'https://etherscan.io',
    etherscanUrl: 'http://api.etherscan.io/api?apikey=YourApiKeyToken',
    snFilesUri: 'https://files.silentnotary.com'
  },
  eLawToken: 'UBSN',
  startPageIcons: {
    hideGroups: true, //if True - shown only 1 group (Applications by Default), other hidden; if false - shown all group without closing
    noGroups: false, //hide Group names, only icons
    showTitles: true,
    defaultIconSrc: "../assets/svg/icons/warning.svg", //if src not defined for icon, inserted this image
    applicationsIcons: [
      {
        href: "https://ubix.network/",
        src: "../assets/svg/icons/apps/UBIX_network.svg",
        title: "Ubix.Network"
      },
      {
        href: "https://silentnotary.com/",
        src: "../assets/svg/icons/apps/Silnet_Notary.svg",
        title: "Silent Notary"
      },
      {
        href: "https://ubistake.io/",
        src: "../assets/svg/icons/apps/UBISTAKE.svg",
        title: "UbiStake"
      },
      {
        href: "https://explorer.ubikiri.com/",
        src: "../assets/svg/icons/apps/UBIX_explorer.svg",
        title: "Ubix Explorer"
      }
    ],
    webResourcesIcons: [
      {
        href: "https://ubix.exchange/",
        src: "../assets/svg/icons/apps/UBIX_exchange.svg",
        title: "UbixExchange"
      }
    ],
    documentationIcons: []
  }
};
