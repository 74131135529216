import { initRequestInProgress, requestInProgressNames, setRequestInProgress, resetRequestInProgress, State } from './users.state';
import { Actions, ActionTypes } from '@app/_stores/users/users.actions';

const initialState: State = {
  users: null,
  userProperties: null,
  showInvitedUsersTable: false,
  countInvitedUsers: null,
  errors: null,
  requestInProgress: initRequestInProgress()
};

export function usersReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.FETCH_USERS_BY_IDS:
      return {
        ...state,
        requestInProgress: setRequestInProgress(state, requestInProgressNames.users)
      };
    case ActionTypes.FETCH_COUNT_INVITED_USERS:
      return {
        ...state,
        requestInProgress: setRequestInProgress(state, requestInProgressNames.countInvitedUsers)
      };
    case ActionTypes.SET_COUNT_INVITED_USERS:
      return {
        ...state,
        requestInProgress: resetRequestInProgress(state, requestInProgressNames.countInvitedUsers),
        countInvitedUsers: action.payload
      };
    case ActionTypes.FETCH_USER_PROPERTIES:
      return {
        ...state,
        requestInProgress: setRequestInProgress(state, requestInProgressNames.userProperties)
      };
    case ActionTypes.SET_USER_PROPERTIES:
      return {
        ...state,
        requestInProgress: resetRequestInProgress(state, requestInProgressNames.userProperties),
        userProperties: action.payload
      };
    case ActionTypes.SHOW_INVITED_USERS_TABLE:
      return {
        ...state,
        showInvitedUsersTable: true,
      };
    case ActionTypes.SET_USERS:
      return {
        ...state,
        requestInProgress: resetRequestInProgress(state, requestInProgressNames.users),
        users: action.payload
      };
    case ActionTypes.SET_ERRORS:
      return {
        ...state,
        requestInProgress: initRequestInProgress(),
        errors: action.payload
      };
    default:
      return state;
  }
}
